import React from "react";

import ptBr from "../../../config/texts/pt-br";
import {
  ButtonText,
  CustomText,
  FontStyles,
  Input,
  InputAutocomplete,
  InputDate,
  Tooltip,
} from "../../../components/index";
import Styles from "../styles";
import { ErrorMessageType, FormFull } from "form-full";
import { hooks, masks, validations } from "../../../utils";

import { fonts, Spacing } from "../../../config";
import { Grid } from "@material-ui/core";
import { customModal } from "../../../components/modals/utils";
import alerts from "../../../utils/alerts";
import { api } from "../../../services";
import colors from "../../../config/colors";
import SelectComponent from "../../../components/inputs/Select";
import { AccessTime, Label } from "@material-ui/icons";
import errorResponseMessage from "../../../services/helpers/getErrorMessage";
import ResponseError from "../../../services/helpers/ResponseError";
import errorStatusMessage from "../../../services/helpers/errorStatusMessage";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import '../../../phone.css'
import { LabelView } from "../../../components/inputs/Input";
import styled from "styled-components";

interface ExtraGuest {
  fullName: string;
  email: string;
}

interface GuestInfo {
  name?: string;
  cpf?: string;
  birthDate?: string;
  email?: string;
  phone?: string;
  id?: string | undefined | null;
  passport?: string;
}
interface FormSendData {
  id: string;
  property: string;
  numGuests: number;
  placeId: string;
  startDate: string;
  endDate: string;
  checkOutTime: string;
  checkInTime: string;
  fullName: string;
  cpf: string;
  birthday: string;

  email: string;
  phone: string;
  numberUsers: number;
  primaryGuest: GuestInfo;
  secondaryGuests: Partial<ExtraGuest>[];
  docType: number;

  docValue: string;
}

export const MyLabel = styled.p(({ theme, $withError, $color, white }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.semibold14,
    textTransform: "uppercase",
    padding: 0,
    paddingBottom: 10,
    margin: 0,
    color: $withError
      ? colors.error.main
      : white
      ? "white"
      : colors.action.selected,
    transition: ".2s",
    pointerEvents: "none",
    alignItems: "center",
    display: "flex",
    overflow: "hidden",
  };
});

function RegistryReservation({
  reload,
  placeList,
  page,
  edit,
  oldData,
}: {
  reload: (data: any) => void;
  placeList: { value: string; label: string }[];
  page: number;
  edit?: boolean;
  oldData?: any;
}) {
  const texts = ptBr.login;
  const { loading, call } = hooks.useRequest();

  const [totalMembers, setTotalMembers] = React.useState(0);
  const [formRef, setFormRef] = React.useState<any>(null);
  const [minDate, setMinDate] = React.useState<any>(new Date());
  const [unError, setUnError] = React.useState<any>(false);
  const [timeError, setTimeError] = React.useState<any>(false);
  const [hourError, setHourError] = React.useState<any>(false);
  const [docType, setDocType] = React.useState<any>(1);
  const phone = React.useRef<any>()
  function setPhone(value){
    phone.current = value;
  }
  
  const [editValues, setEditValues] = React.useState<
    Partial<FormSendData> | undefined
  >(
    oldData
      ? {
          property: oldData?.place?.id,
          startDate: oldData?.startDate,
          checkInTime: oldData?.startDate.split("T")[1],
          checkOutTime: oldData?.endDate.split("T")[1],
          endDate: oldData?.endDate,
          docType:
            oldData?.primaryGuest?.cpf && Boolean(oldData?.primaryGuest?.cpf)
              ? 1
              : 2,
          fullName: oldData?.primaryGuest?.name,
          birthday: oldData?.primaryGuest?.birthDate,
          email: oldData?.primaryGuest?.email,
          phone: oldData?.primaryGuest?.phone,
          numberUsers: oldData?.numGuests,
        }
      : {
          checkInTime: "15:00",
          checkOutTime: "10:00",
        }
  );

  React.useEffect(()=>{
    if(oldData){
      phone.current = (oldData.primaryGuest?.phone);
    }
  },[]);

  React.useEffect(() => {
    if (formRef) {
      let extraGuests: any = {};

      oldData?.secondaryGuests.forEach((element, index) => {
        extraGuests["fullName" + (index + 1)] = element?.name;
        extraGuests["email" + (index + 1)] = element?.email;
      });

      if (oldData?.primaryGuest?.cpf && Boolean(oldData?.primaryGuest?.cpf)) {
        extraGuests["docValue"] = oldData?.primaryGuest?.cpf;
        setDocType(1);
      } else if (
        oldData?.primaryGuest?.passport &&
        Boolean(oldData?.primaryGuest?.passport)
      ) {
        extraGuests["docValue"] = oldData?.primaryGuest?.passport;
        setDocType(2);
      }

      setEditValues((v) => {
        return { ...v, ...extraGuests };
      });
    }
  }, [totalMembers, formRef]);

  const drawMembers = (number: number) => {
    const membersForm: any = [];

    for (let index = 0; index < number; index++) {
      membersForm.push(
        <>
          <CustomText
            fontFamily={fonts.bold}
            fontSize={14 / 8}
            textColor="#B2B2B2"
            style={{ paddingTop: "12px", paddingBottom: "4px" }}
          >
            ACOMPANHANTE #{index + 1}
          </CustomText>
          <Grid alignItems="flex-end" container spacing={1}>
            {/*  <Grid item md={6} lg={4}>
              <Input
                disableError
                required={"*Campo Obrigatório"}
                mask={masks.CPF}
                name={"cpf" + (index + 1)}
                label="CPF"
                white
                maskToSubmit={masks.removeNumberMask}
                validation={validations.validateCPF}
              />
            </Grid> */}
            <Grid item xs={12} md={12} lg={5}>
              <Input
                disableError
                name={"fullName" + (index + 1)}
                label="Nome Completo"
                white
                validation={validations.isValidFullname}
              />
            </Grid>
            {/*  <Grid item md={6} lg={4}>
              <InputDate
                disableError
                required={"*Campo Obrigatório"}
                name={"birthday" + (index + 1)}
                label="DATA NASCIMENTO"
              />
            </Grid> */}
            <Grid item xs={12} md={12} lg={7}>
              <Input
                disableError
                validation={validations.isEmailValid}
                name={"email" + (index + 1)}
                label="E-Mail"
                white
              />
            </Grid>
            {/* <Grid item md={6} lg={6}>
              <Input
                disableError
                required={"*Campo Obrigatório"}
                mask={masks.inputMaskTELWithDDD}
                name={"phone" + (index + 1)}
                label="Telefone"
                white
                maskToSubmit={masks.removeNumberMask}
              />
            </Grid> */}
          </Grid>
        </>
      );
    }
    return membersForm;
  };

  const newReservation = async (data) => {
    const submitDate: Partial<FormSendData> = {
      numGuests: data.numberUsers ? parseInt(data.numberUsers) : 0,
      placeId: data.property,
      startDate: masks.dateToSubmit(data.startDate) + " " + data?.checkInTime,
      endDate: masks.dateToSubmit(data.endDate) + " " + data?.checkOutTime,
      primaryGuest: {
        name: data.fullName,
        cpf:
          data.docType === 1 || data.docType === "1"
            ? data.docValue
            : undefined,
        passport:
          data.docType === 2 || data.docType === "2"
            ? data.docValue
            : undefined,
        birthDate: masks.dateToSubmit(data.birthday),
        email: data.email,
        phone: data.phone,
      },
    };
    const secondaryGuests: Partial<ExtraGuest>[] = [];

    if (parseInt(data.numberUsers) > 0) {
      for (let index = 1; index < data.numberUsers + 1; index++) {
        if (data["fullName" + index] || data["email" + index]) {
          let newGuest = {} as any;
          if (data["fullName" + index]) {
            newGuest["name"] = data["fullName" + index];
          }
          if (data["email" + index]) {
            newGuest["email"] = data["email" + index];
          }
          secondaryGuests.push(newGuest);
        }
      }
    }
    submitDate["secondaryGuests"] = secondaryGuests;

    if (edit) {
      delete submitDate.placeId;
      delete submitDate.numGuests;
      submitDate["id"] = oldData.id;

      submitDate.secondaryGuests = submitDate.secondaryGuests.map(
        (i, index) => {
          return { ...i, id: oldData?.secondaryGuests[index]?.id };
        }
      );
      submitDate.primaryGuest = {
        ...submitDate.primaryGuest,
        id: oldData?.primaryGuest?.id,
      };
      call(null, api.patchReservation(submitDate), async (response) => {
        if (response.ok) {
          customModal.close();
          reload(page);
          alerts.setErrorModal(
            "Reserva Editada",
            ["Reserva editada com sucesso!"],
            null,
            "Ok, Entendi"
          );
        }
      });
    } else {
      call(null, api.postReservation(submitDate), async (response) => {
        if (response.ok) {
          customModal.close();
          reload(page);
          alerts.setErrorModal(
            "Reserva Cadastrada",
            ["Reserva cadastrada com sucesso!"],
            null,
            "Ok, Entendi"
          );
        }
      });
    }
  };

  const customDateValid = (data, maxDate, minDate): ErrorMessageType => {
    const error = validations.inputDate(data, maxDate, minDate);

    if (Boolean(error)) {
      setTimeError("*Período invalido");
    } else {
      setTimeError(false);
    }
    return error;
  };

  const addOneDay = (date) => {
    const result = new Date(date);
    result.setDate(result.getDate() + 1);
    return result;
  };

  function isValidDate(date: any): boolean {
    const parsedDate = new Date(date);
    return parsedDate instanceof Date && !isNaN(parsedDate.getTime());
  }

  const validadeTime = () => {
    const localId = formRef?.getValue("property", false);
    const startDate = formRef?.getValue("startDate", false);
    const endDate = formRef?.getValue("endDate", false);
    const startHour = formRef?.getValue("checkInTime", false);
    const endHour = formRef?.getValue("checkOutTime", false);

    const timeEndError = validations.validateTimeRange(endHour);
    const timeStartHour = validations.validateTimeRange(startHour);
    
    if (
      !timeEndError &&
      !timeStartHour &&
      isValidDate(startDate) &&
      isValidDate(endDate) &&
      localId &&
      endDate &&
      endHour &&
      startDate &&
      startHour
    ) {
      const startSub = masks.dateToSubmit(startDate) + " " + startHour;
      const endSub = masks.dateToSubmit(endDate) + " " + endHour;

      let subData = {
        placeId: localId,
        startDate: startSub,
        endDate: endSub,
      };

      if (edit) {
        subData["reservationId"] = oldData.id;
      }

      call(
        null,
        api.validadeTime(subData),
        (response) => {
          if (response?.ok) {
            if (response?.data?.value === false) {
              setHourError(null);
            } else {
              setHourError("Esse horário esta em uso");
            }
          }
        },
        (response) => {
          const errorResponse = new ResponseError(response);
          if (errorResponse.message) {
            setHourError(errorResponse.message);
          } else {
            const errorMsg = errorStatusMessage(response);

            alerts.alertError(errorMsg);
            setHourError(
              "*Não foi possível validar o horário. Tente Novamente"
            );
          }
        }
      );
    } else {
      setHourError("Selecione um Local e Período");
    }
  };

  function checkStartData():boolean {//Devolve se a data selecionada é igual a inicial ou menor que a atual.
    const startDate = formRef?.getValue("startDate", false);
    if(oldData){
      if(!(oldData?.startDate<startDate)&&
         !(oldData?.startDate>startDate)){
        return true;
      }
    }
    return startDate<new Date();
  }
  
  const customDateValidStartData = (data, maxDate, minDate): ErrorMessageType => {
    if(data+""===oldData?.startDate+""){
      setTimeError(false);
      return;
    }

    const error = validations.inputDate(data, maxDate, minDate);

    if (Boolean(error)) {
      setTimeError("*Período invalido");
    } else {
      setTimeError(false);
    }
    return error;
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          minWidth: "clamp(300px, 54vw, 1055px)",
          paddingInline: "25px",
        }}
      >
        <FormFull
          formRef={setFormRef}
          onSubmit={(data) => {
            data.phone = phone.current+"";
            newReservation(data);
          }}
          currentValues={editValues}
        >
          <CustomText
            fontFamily={fonts.bold}
            fontSize={14 / 8}
            textColor="#B2B2B2"
          >
            Dados do Imóvel e Estadia
          </CustomText>
          <Grid alignItems="flex-end" container spacing={1}>
            <Grid item xs={12} md={12} lg={3}>
              <InputAutocomplete
                readOnly={edit}
                defaultValue={oldData?.place?.id}
                disableError
                required={"*Campo Obrigatório"}
                white
                name="property"
                label="Imóvel"
                options={placeList ? placeList : []}
                onBlur={validadeTime}
              />
            </Grid>{" "}
            <Grid container item xs={12} md={12} lg={5}>
              <Styles.Label $witherror={Boolean(timeError) ? true : undefined}>
                estadia*
              </Styles.Label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                  justifyContent: "space-between",
                  gap: "15px",
                  width: "100%",
                }}
              >
                <InputDate
                  onBlur={validadeTime}
                  disableError
                  required={"*Campo Obrigatório"}
                  onChange={(value) => {
                    setMinDate(value);
                    const end = formRef?.getValue("endDate");

                    if (end < value) {
                      formRef?.clearValue("endDate", false);
                    }
                  }}
                  name="startDate"
                  customValidation={customDateValidStartData}
                  minDate={new Date()}
                />{" "}
                <CustomText
                  fontFamily={fonts.medium}
                  fontSize={14 / 8}
                  textColor="#fff"
                  style={{ paddingBottom: "16px" }}
                >
                  até
                </CustomText>{" "}
                <InputDate
                  disableError
                  required={"*Campo Obrigatório"}
                  minDate={addOneDay(minDate)}
                  name="endDate"
                  customValidation={customDateValid}
                  onBlur={validadeTime}
                />
              </div>
            </Grid>
            <Grid item xs={6} md={6} lg={2}>
              <Input
                onBlur={validadeTime}
                disableError
                required={"*Campo Obrigatório"}
                mask={masks.formatHour}
                placeholder="00:00"
                validation={validations.validateTimeRange}
                name="checkInTime"
                label="Check-In"
                endAdornment={<AccessTime style={{ fontSize: Spacing(2) }} />}
                white
              />
            </Grid>
            <Grid item xs={6} md={6} lg={2}>
              <Input
                endAdornment={<AccessTime style={{ fontSize: Spacing(2) }} />}
                disableError
                required={"*Campo Obrigatório"}
                mask={masks.formatHour}
                placeholder="00:00"
                validation={validations.validateTimeRange}
                name="checkOutTime"
                label="Check-Out"
                onBlur={validadeTime}
                white
              />
            </Grid>
          </Grid>
          <CustomText
            fontFamily={fonts.bold}
            fontSize={14 / 8}
            textColor="#B2B2B2"
            style={{ paddingTop: "12px", paddingBottom: "4px" }}
          >
            LOCATÁRIO PRIMÁRIO
          </CustomText>
          <Grid alignItems="flex-end" container spacing={1}>
            <Grid item xs={12} md={6} lg={3}>
              <Input
                disableError
                required={"*Campo Obrigatório"}
                name="fullName"
                validation={validations.isValidFullname}
                label="Nome Completo"
                white
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <SelectComponent
                disableError
                required={"*Campo Obrigatório"}
                name="docType"
                label="TIPO DO DOCUMENTO"
                white
                defaultValue={1}
                onBlurCustom={() => {
                  formRef?.testFieldError("documento", true);
                }}
                onChangeCustom={(v) => {
                  setDocType(v);

                  formRef?.clearValue("docValue", true);

                  if (v === 1) {
                    formRef?.setFieldValidation(
                      "docValue",
                      validations.validateCPF
                    );
                  } else {
                    formRef?.setFieldValidation("docValue", (value) => {
                      return null;
                    });
                  }
                }}
                options={[
                  { value: 1, label: "CPF" },
                  { value: 2, label: "Passaporte" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Input
                disableError
                required={"*Campo Obrigatório"}
                mask={docType === 1 ? masks.CPF : undefined}
                name="docValue"
                label={
                  docType === 1 ? "CPF" : docType === 2 ? "Passaporte" : "CPF"
                }
                white
                maskToSubmit={(e, ffHandler) => {
                  let type = ffHandler?.getValue("docType", false);

                  if (type === 1) {
                    return masks.removeNumberMask(e);
                  } else {
                    return e;
                  }
                }}
                validation={(e, ffHandler) => {
                  let type = ffHandler?.getValue("docType", false);

                  if (type === 1) {
                    return validations.validateCPF(e);
                  } else {
                    return undefined;
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <InputDate
                disableError
                required={"*Campo Obrigatório"}
                maxDate={new Date()}
                name="birthday"
                label="DATA NASCIMENTO"
              />{" "}
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
              <Input
                disableError
                required={"*Campo Obrigatório"}
                validation={validations.isEmailValid}
                name="email"
                label="E-Mail"
                white
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <LabelView>
                <MyLabel
                  white
                  className={"form-input-label-" + "telefone"?.replace(/\s/g, "")}
                >
                  {"Telefone*"}
                </MyLabel>
              </LabelView>
              <div style={{backgroundColor:"#E9E9E9", height:"48px", alignContent:"center", border:"0px", borderRadius:"5px", padding:"5px"}}>
              <PhoneInputWithCountrySelect
                placeholder="Telefone"
                numberInputProps={{className:"focus:outline-none focus:ring-0",}}
                value={phone.current}
                onChange={setPhone}
              />
              </div>
              {/*
              <Input
                disableError
                required={"*Campo Obrigatório"}
                mask={masks.inputMaskTELWithDDD}
                name="phone"
                label="Telefone"
                white
                maskToSubmit={masks.removeNumberMask}
              />
              */}
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Input
                readOnly={edit}
                disableError
                required={"*Campo Obrigatório"}
                onChange={(value) => {
                  setTotalMembers(masks.onlyNumbers(value, 0, 10));
                }}
                defaultValue={totalMembers}
                mask={(value) => masks.onlyNumbers(value, 0, 10)}
                maskToSubmit={(value) => masks.onlyNumbers(value, 0, 10)}
                type="number"
                name="numberUsers"
                label="Nº ACOMPANHANTES"
                white
              />
            </Grid>
          </Grid>
          {drawMembers(totalMembers)}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "12px",
              paddingTop: "12px",
              width: "100%",
            }}
          >
            <CustomText
              style={{ whiteSpace: "pre-wrap" }}
              fontSize={1.75}
              textColor="#f00"
            >
              {unError && "*Campos Obrigatórios \n"}
              {timeError && timeError + "\n"}
              {hourError && "*" + hourError}
            </CustomText>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "12px",
              }}
            >
              <Styles.CancelButton
                loading={loading}
                disabled={loading}
                onClick={() => {
                  customModal.close();
                }}
                fullWidth={false}
              >
                Cancelar
              </Styles.CancelButton>
              <Styles.RegitryButton
                fullWidth={false}
                loading={loading}
                disabled={loading}
                onClick={async () => {
                  const submitInfo = await formRef?.testErrorsAndReturnData();
                  const startError = !checkStartData();
                  const endError = await formRef?.testFieldError("endDate");

                  if (Boolean(startError) || Boolean(endError)) {
                    if (startError) {
                      setTimeError("Data inicial: " + startError);
                    } else {
                      setTimeError("Data final: " + endError);
                    }
                  } else {
                    setTimeError(false);
                  }

                  if (submitInfo?.hasError || hourError) {
                    setUnError(true);
                  } else {
                    setUnError(false);
                    formRef?.submit();
                  }
                }}
              >
                Confirmar Cadastro
              </Styles.RegitryButton>
            </div>
          </div>
        </FormFull>
      </div>
    </>
  );
}

export default RegistryReservation;
