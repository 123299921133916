import React, { useEffect, useState } from "react";
import { hooks } from "../../../utils";
import alerts from "../../../utils/alerts";
import Styles from "../EsqueceuSenhaStyles";

const TIME_IN_MILISECONDS_TO_COUNTDOWN = 90 * 1000;
const INTERVAL_IN_MILISECONDS = 1000;
function Countdown(props) {
  const [timer, setTimer] = useState<number>(TIME_IN_MILISECONDS_TO_COUNTDOWN);
  const [referenceTime, setReferenceTime] = useState(Date.now());
  const { loading, call } = hooks.useRequest();
  useEffect(() => {
    props.setReset({
      callback: () => {
        setTimer(90 * 1000);
        setReferenceTime(Date.now());
      },
    });
  }, [props.setReset]);
  useEffect(() => {
    const countDownUntilZero = () => {
      setTimer((prevTime) => {
        if (prevTime <= 0) return 0;

        const now = Date.now();
        const interval = now - referenceTime;

        setReferenceTime(now);
        return prevTime - interval;
      });
    };

    if (timer > 0) {
      setTimeout(countDownUntilZero, INTERVAL_IN_MILISECONDS);
    }
  }, [timer, referenceTime]);

  const ReSendCode = async () => {
    props.setReSend(() => {
      setTimer(90 * 1000);
      setReferenceTime(Date.now());
      alerts.alertSuccess("Código reenviado ao e-mail com sucesso!");
    });
  };

  return (
    <Styles.ReSend
      disabled={loading || props.loading || timer <= 0 ? false : true}
      loading={loading || props.loading}
      onClick={() => timer <= 0 && ReSendCode()}
    >
      Reenviar{" "}
      {timer <= 0
        ? ""
        : "(" +
          `${
            Math.floor(timer / 60000) < 0
              ? "00"
              : String("0" + parseInt(String(Math.floor(timer / 60000))))
          }` +
          ":" +
          `${
            (timer % 60000) / 1000 < 0
              ? "00"
              : String(
                  `${(timer % 60000) / 1000 < 10 ? "0" : ""}` +
                    parseInt(String((timer % 60000) / 1000))
                )
          }` +
          ")"}
    </Styles.ReSend>
  );
}

export default Countdown;
