import React from "react";

import ptBr from "../../../config/texts/pt-br";
import {
  ButtonText,
  CustomText,
  Input,
  InputAutocomplete,
  InputDate,
} from "../../../components/index";
import Styles from "../styles";
import { FormFull } from "form-full";
import { hooks, masks } from "../../../utils";

import { fonts, Spacing, SVG } from "../../../config";
import { Grid } from "@material-ui/core";
import { customModal } from "../../../components/modals/utils";
import { Visibility } from "@material-ui/icons";
import { api } from "../../../services";
import ModalImage from "./ModalImage";

function ModalView({ onSubmit, item }) {
  const texts = ptBr.login;
  const { call } = hooks.useRequest();

  const drawMembers = (number: number) => {
    const membersForm: any = [];

    for (let index = 0; index < number; index++) {
      membersForm.push(
        <>
          <CustomText
            fontFamily={fonts.bold}
            fontSize={14 / 8}
            textColor="#B2B2B2"
            style={{ paddingTop: "25px" }}
          >
            ACOMPANHANTE #{index + 1}
          </CustomText>
          <Grid alignItems="flex-end" container spacing={1}>
            <Grid item xs={12} md={6} lg={4}>
              <Styles.Label>Nome Completo</Styles.Label>
              <Styles.Value>{item?.secondaryGuests[index]?.name}</Styles.Value>
            </Grid>{" "}
            <Grid item xs={12} md={6} lg={4}>
              <Styles.Label>Documento</Styles.Label>
              <Styles.Value>
                {item?.secondaryGuests[index]?.cpf ? masks.CPF(item?.secondaryGuests[index]?.cpf) : item?.secondaryGuests[index]?.passport}
              </Styles.Value>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Styles.Label>DATA NASCIMENTO</Styles.Label>
              <Styles.Value>
                {item?.secondaryGuests[index]?.birthDate &&
                  masks.formatDate(item?.secondaryGuests[index].birthDate)}
              </Styles.Value>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Styles.Label>E-Mail</Styles.Label>
              <Styles.Value>{item?.secondaryGuests[index]?.email}</Styles.Value>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Styles.Label>Telefone</Styles.Label>
              <Styles.Value>
                {masks.inputMaskTELWithDDD(item?.secondaryGuests[index]?.phone)}
              </Styles.Value>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Styles.Label>ASSINATURA FACIAL</Styles.Label>
              <Styles.Value>
                {item?.secondaryGuests[index]?.imageId ? "doc-facial.png" : "Sem Imagem"}
                <Styles.ValueSpan
                  $disabled={item?.secondaryGuests[index]?.imageId === null}
                  onClick={() => {
                    if (item?.secondaryGuests[index]?.imageId !== null) {
                      openModalViewImg(item, item?.secondaryGuests[index]);
                      // downloadImage(item?.secondaryGuests[index]?.imageId);
                    }
                  }}
                >
                  Visualizar <Visibility />
                </Styles.ValueSpan>
              </Styles.Value>
            </Grid>
          </Grid>
        </>
      );
    }
    return membersForm;
  };

  const openModalViewImg = (fullItem, imageData) => {
    customModal.setInfos(
      "doc-facial.png",
      [],
      {
        onClick: () => {
          customModal.close();
          openModalView(fullItem);
        },
        label: "Voltar",
      },
      null,
      <ModalImage item={imageData} />,
      false,
      true,
      null
    );
  };

  const openModalView = (item) => {
    customModal.setInfos(
      "Cadastrar Reserva",
      [],
      {
        onClick: () => {
          customModal.close();
        },
        label: "Voltar",
      },
      null,
      <ModalView item={item} onSubmit={(value) => console.log(value)} />,
      false,
      true,
      <SVG.File />
    );
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          minWidth: "clamp(300px, 54vw, 1055px)",
          paddingInline: "25px",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",

            padding: "24px",
            backgroundColor: "#383B3E",
            overflow: "auto",
            maxHeight: "430px",
            borderRadius: "5px",
          }}
        >
          <FormFull
            onSubmit={(data) => {
              onSubmit(data);
            }}
          >
            <CustomText
              fontFamily={fonts.bold}
              fontSize={14 / 8}
              textColor="#B2B2B2"
            >
              LOCATÁRIO PRIMÁRIO
            </CustomText>
            <Grid alignItems="flex-end" container spacing={1}>
              {" "}
              <Grid item xs={12} md={6} lg={4}>
                <Styles.Label>Nome Completo</Styles.Label>
                <Styles.Value>{item?.primaryGuest?.name}</Styles.Value>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Styles.Label>Documento</Styles.Label>
                <Styles.Value>
                  {item?.primaryGuest?.cpf ? masks.CPF(item?.primaryGuest?.cpf) : item?.primaryGuest?.passport}
                </Styles.Value>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Styles.Label>DATA NASCIMENTO</Styles.Label>
                <Styles.Value>
                  {" "}
                  {item?.primaryGuest?.birthDate
                    ? masks.formatDate(item?.primaryGuest?.birthDate)
                    : "-"}
                </Styles.Value>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Styles.Label>E-Mail</Styles.Label>
                <Styles.Value>{item?.primaryGuest?.email}</Styles.Value>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Styles.Label>Telefone</Styles.Label>
                <Styles.Value>
                  {masks.inputMaskTELWithDDD(item?.primaryGuest?.phone)}
                </Styles.Value>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Styles.Label>ASSINATURA FACIAL</Styles.Label>
                <Styles.Value>
                  {item.primaryGuest.imageId ? "doc-facial.png" : "Sem Imagem"}
                  <Styles.ValueSpan
                    $disabled={item.primaryGuest.imageId === null}
                    onClick={() => {
                      if (item.primaryGuest.imageId !== null) {
                        openModalViewImg(item, item.primaryGuest);
                        // downloadImage(item.primaryGuest.imageId);
                      }
                    }}
                  >
                    Visualizar <Visibility />
                  </Styles.ValueSpan>
                </Styles.Value>
              </Grid>
            </Grid>
            {drawMembers(item.numGuests)}
          </FormFull>
        </div>
      </div>
    </>
  );
}

export default ModalView;
