import {
  DatePickerView,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import dateFns from "@date-io/date-fns";
import styled from "styled-components";
import FontStyles from "../styles/fontStyles";
import {
  createMuiTheme,
  FormControl,
  FormHelperText,
  TextFieldProps,
  Theme,
  ThemeProvider,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import brLocate from "date-fns/locale/pt-BR";
import { Colors, fonts, Spacing } from "../../config";
import { ErrorMessageType, useFormFull } from "form-full";
import { validations } from "../../utils";
import { WrapperVariant } from "@material-ui/pickers/wrappers/Wrapper";
import colors from "../../config/colors";

export type TextInputDateBaseProps = {
  label?: string;
  alternativeColors?: boolean;
  inputStyle?: styled;
  required?: string;
  readOnly?: boolean;
  icon?: any;
  theme?: any;
  disableError?: boolean;
  borderRadius?: any;
  variant?: WrapperVariant;
  views?: DatePickerView[];
  autoOk?: boolean;
  format?: string;
  minDate?: any;
  maxDate?: any;
  autoComplete?: string;
  end?: string;
  restProps?: any;
  name: string;
  defaultValue?: any;
  onChange?: (value: any) => void;
  customValidation?: (
    data: any,
    maxDate: any,
    minDate: any
  ) => ErrorMessageType;

  onBlur?: any;
};

const customTheme: Theme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: colors.black,
        borderBottom: "1px solid #24272A",
        [`@media (max-width:${581 - 1}px)`]: {
          height: Spacing(6.25),
        },
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: Colors.inputGray,

        height: 50,
        fontFamily: fonts.medium,
        fontSize: fonts.sizes.regular,
        "&:hover": {
          backgroundColor: `${Colors.inputGray} !important`,
        },
        "&$focused": { backgroundColor: "white" },
        borderRadius: "4.8px !important",
      },
      input: {
        padding: "6px 8px 7px 8px",
      },
    },
    MuiIconButton: {
      root: {
        color: Colors.black,
        paddingInline: "0px",
      },
    },

    MuiPickersToolbarText: {
      toolbarTxt: {
        fontFamily: fonts.regular,
      },
      toolbarBtnSelected: {
        color: "white",
      },
    },
    // Header
    MuiPickersCalendarHeader: {
      // Arrow
      iconButton: {
        backgroundColor: "transparent",
        color: colors.black,
        [`@media (max-width:${581 - 1}px)`]: {
          marginInline: Spacing(1.5),
        },
      },
      // Texto dia da semana
      dayLabel: {
        fontFamily: fonts.regular,
        color: colors.black,
        [`@media (max-width:${581 - 1}px)`]: {
          fontSize: Spacing(1),
        },
      },
    },
    // Header e corpo texto
    MuiTypography: {
      // Header texto mês e ano
      alignCenter: {
        fontFamily: fonts.regular,
        color: colors.black,
        [`@media (max-width:${581 - 1}px)`]: {
          fontSize: Spacing(1.5),
        },
      },
      // Corpo dias
      body2: {
        fontFamily: fonts.regular,

        [`@media (max-width:${581 - 1}px)`]: {
          fontSize: Spacing(1.5),
        },
      },
    },
    // Corpo
    MuiPickersBasePicker: {
      pickerView: {
        backgroundColor: colors.inputGray,
        [`@media (max-width:${581 - 1}px)`]: {
          minHeight: "300px",
        },
      },
    },
    // Dias
    MuiPickersDay: {
      // Dias
      day: { color: colors.black },
      // Dia selecionado
      daySelected: {
        backgroundColor: colors.gray,
        color: colors.whiteBackground,
        "&:hover": {
          backgroundColor: colors.black + "E6",
          color: "white",
          border: "1px solid #24272A",
        },
      },
      // Dia atual
      current: { color: "#3f51b5" },
    },
    // Footer
    MuiDialogActions: {
      root: {
        backgroundColor: colors.black,
        [`@media (max-width:${581 - 1}px)`]: {
          padding: Spacing(0, 1, 1, 0),
        },
      },
    },
    // Footer botões
    MuiButton: {
      root: { minWidth: "32px" },
      label: {
        color: colors.whiteBackground,
        fontFamily: fonts.bold,
        [`@media (max-width:${581 - 1}px)`]: {
          fontSize: Spacing(1.4),
          width: "auto",
        },
      },
      text: {
        [`@media (max-width:${581 - 1}px)`]: {
          padding: Spacing(0),
        },
      },
    },
    // Texto ano
    MuiPickersYear: {
      root: { fontFamily: fonts.regular },
      yearSelected: { color: colors.black },
    },
    // Texto mês
    MuiPickersMonth: {
      monthSelected: { color: colors.black },
    },
  },
} as any);

const Label = styled.p(({ theme, $witherror }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.semibold14,
    textTransform: "uppercase",
    color: $witherror ? colors.error.main : colors.text.secondary,
    transition: ".2s",
    pointerEvents: "none",
  };
});

const ErrorMessage = styled(FormHelperText)(({ theme }) => {
  return {
    color: "red",
    minHeight: Spacing(2.5),
  };
});

function InputDateComponent(props: TextInputDateBaseProps) {
  const {
    label,
    icon,
    theme,
    required,
    disableError,
    borderRadius,
    variant,
    inputStyle,
    views = ["year", "month", "date"],
    autoOk = true,
    format,
    readOnly,
    minDate,
    maxDate,
    alternativeColors,
    autoComplete,
    end,
    customValidation,
    ...restProps
  } = props;

  const { value, error, onBlur, onChange, ref, ffHandler } = useFormFull.field({
    ...props,
    validation: (data): ErrorMessageType => {
      return customValidation
        ? customValidation(data, props.maxDate, props.minDate)
        : validations.inputDate(data, props.maxDate, props.minDate);
    },
  });

  function getHint(error) {
    if (error) return error;

    return null;
  }

  return (
    <FormControl fullWidth error={Boolean(error)}>
      <MuiPickersUtilsProvider locale={brLocate} utils={dateFns}>
        {label && (
          <Label $witherror={Boolean(error) ? true : undefined}>
            {label}
            {Boolean(error) && label && disableError && required && "*"}
          </Label>
        )}
        <ThemeProvider theme={customTheme}>
          <KeyboardDatePicker
            {...restProps}
            {...(readOnly ? { open: !readOnly } : {})}
            inputVariant="filled"
            format={format ? format : "dd/MM/yyyy"}
            minDate={
              typeof minDate !== "string" && minDate !== null
                ? minDate
                : undefined
            }
            maxDate={
              typeof maxDate !== "string" && maxDate !== null
                ? maxDate
                : undefined
            }
            InputProps={{
              disableUnderline: true,
              readOnly: readOnly,
            }}
            value={value ? value : null}
            required={Boolean(required)}
            variant={variant}
            fullWidth
            views={views}
            inputRef={ref}
            autoOk={autoOk}
            onChange={(date) => {
              onChange(null, date);
              setTimeout(() => {
                onBlur(ffHandler?.testFieldError(props.name));
              }, 10);
            }}
            error={Boolean(error)}
            helperText={disableError ? null : Boolean(error) ? error : null}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
      {disableError ? null : (
        <ErrorMessage error={Boolean(error)}>{getHint(error)}</ErrorMessage>
      )}
    </FormControl>
  );
}

export default InputDateComponent;
