import React from "react";
import {
  FormControl,
  TextField,
  FormHelperText,
  Popper,
  TextFieldProps,
} from "@material-ui/core";
import styled from "styled-components";
import { withTheme } from "@material-ui/styles";
import { makeStyles } from "@material-ui/core";
import { FieldProps, useFormFull } from "form-full";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { FontStyles } from "../index";
import { fonts } from "../../config";

export type TextInputAutoCompleteBaseProps = {
  label?: string;
  hideVisualError?: boolean;
  smallBottom?: number;
  alternativeColors?: boolean;
  heightInput?: number;
  inputStyle?: styled;
  disabled?: boolean;
  required?: string;
  readOnly?: boolean;
  placeholder?: string;
  justNumbers?: boolean;
  options: Array<{
    label: string;
    value: string | number;
  }>;
  name: string;
  disableError?: boolean;
  white?: boolean;
  defaultValue?: any;
  onBlur?: any;
};

const StyledInput = styled(TextField)(
  ({
    theme,
    disabled,
    $inputStyle,
    $error,
    $smallBottom,
    $alternativecolors,
    $height,
  }) => {
    const { spacing, palette: colors } = theme;
    return {
      ...FontStyles.medium14,
      padding: theme.spacing(1.962, 2.5),
      alignItems: "flex-start",
      paddingTop: spacing(2.5),

      multiline: true,

      textOverflow: "ellipsis",
      color: $error ? "red" : theme.palette.text.primary,
      backgroundColor: theme.palette.primary.contrastText,
      borderBottom: "0px",
      borderRadius: theme.spacing(0.6),
      "& .MuiInputBase-input": {
        padding: 0,
        backgroundColor: "transparent",
        boxShadow: "transparent 0px 0px !important",
      },

      "& .MuiInput-input": {
        color: $alternativecolors ? colors.text.secondary : colors.text.primary,
        fontFamily: fonts.medium,
        fontSize: fonts.sizes.regular,

        "&::placeholder": {
          opacity: 0.8,
        },
      },
      "& .MuiIconButton-root": {
        color: colors.text.primary,
      },
      ...$inputStyle,
    };
  }
);

const Label = styled.p(({ theme, $witherror, disabled, white }) => {
  const { palette: colors, spacing } = theme;
  return {
    ...FontStyles.semibold14,
    textTransform: "uppercase",
    padding: 0,
    margin: 0,

    transition: ".2s",
    pointerEvents: "none",
    alignItems: "center",
    display: "flex",
    overflow: "hidden",
    color: $witherror
      ? colors.error.main
      : white
      ? "white"
      : colors.action.selected,

    opacity: disabled && 0.6,
    marginBottom: spacing(1.5),
  };
});

const ErrorMessage = styled(FormHelperText)(({ theme }) => {
  const { palette: colors } = theme;
  return {
    color: colors.text.secondary,
  };
});

const PopperSelect = styled(Popper)(({ theme }) => {
  const { palette: colors } = theme;
  return {
    "& .MuiAutocomplete-listbox": {
      color: colors.text.primary,
      backgroundColor: colors.primary.contrastText,
      fontFamily: fonts.medium,
      fontSize: fonts.sizes.regular,
      "& li:hover": { backgroundColor: colors.action.focus + 20 },
      '& [aria-selected="true"]': {
        backgroundColor: colors.primary.contrastText + 40,
        borderColor: "transparent",
      },
      "::-webkit-scrollbar-thumb": {
        background: colors.primary.contrastText,
        borderRadius: "15px",
        height: "2px",
      },
    },
  };
});

const AutocompleteCustom = styled(Autocomplete)(({ theme }) => {});

const useStyles = makeStyles((theme) => ({
  root: {},
  inputRoot: {
    '&.MuiAutocomplete-inputRoot[class*="MuiInput-root"]': {
      paddingBlock: theme.spacing(1.0625),
      paddingInline: theme.spacing(2.5),
      margin: 0,
      "&::before": { display: "none" },
      "&::after": { display: "none" },
    },
  },
}));

function InputComponent(props: TextInputAutoCompleteBaseProps) {
  const { value, error, onBlur, ref, onChange, ffHandler } =
    useFormFull.field(props);

  const {
    label,
    hideVisualError,
    smallBottom,
    alternativeColors,
    heightInput,
    options,
    inputStyle,
    disabled,
    required,
    readOnly,
    placeholder = "Todos",
    justNumbers = false,
    disableError,
    white,
  } = props;

  const classes = useStyles();
  let focus = false;

  React.useEffect(() => {
    if (ref && value === "") {
      const ele = ref.current.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0];
      if (ele) {
        ele.click();
      }
    }
  }, [value, ref]);

  const filterOptions = createFilterOptions({
    stringify: (option: any) => option.label?.replace(/[.,/\\-]/g, ""),
  });

  const filterOptionsDefault = createFilterOptions({
    stringify: (option: any) => option.label,
  });

  return (
    <FormControl fullWidth error={hideVisualError ? undefined : Boolean(error)}>
      <AutocompleteCustom
        id={props.name}
        filterOptions={justNumbers ? filterOptions : filterOptionsDefault}
        ref={ref}
        key={label}
        defaultValue={options.find((item) => {
          if (item.value === value) {
            return item;
          }
        })}
        classes={classes}
        componentsProps={{ disableUnderline: true }}
        options={options ? [...options] : null}
        PopperComponent={(props) => {
          return <PopperSelect {...props} />;
        }}
        clearOnBlur={false}
        clearOnEscape={false}
        disabled={readOnly || disabled}
        disableUnderline={true}
        getOptionLabel={(option) => option.label || ""}
        onChange={(event, value) => {
          if (value === null || value === -1) {
            onChange(event, null);
          } else {
            onChange(event, value.value);
          }
          setTimeout(() => {
            onBlur(ffHandler?.testFieldError(props.name));
          }, 10);
        }}
        renderInput={(params) => (
          <>
            <Label
              white={white}
              disabled={disabled}
              $withValue={Boolean(value) || value === 0 || focus}
              $witherror={Boolean(error) ? true : undefined}
            >
              {label}{" "}
              {Boolean(error) && label && disableError && required && "*"}
            </Label>
            <StyledInput
              placeholder={placeholder}
              readOnly={readOnly}
              InputProps={{
                disableUnderline: true,
                underline: "none",
              }}
              value={value}
              onFocus={() => (focus = true)}
              disableUnderline={true}
              required={required}
              $inputStyle={inputStyle}
              $alternativecolors={alternativeColors}
              $height={heightInput}
              $smallBottom={smallBottom}
              $error={Boolean(error)}
              error={Boolean(error)}
              {...params}
            />
          </>
        )}
      />

      {disableError ? null : (
        <ErrorMessage error={Boolean(error)}>
          {Boolean(error) ? error : " "}
        </ErrorMessage>
      )}
    </FormControl>
  );
}

export default withTheme(InputComponent);
