import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ptBr from "../../config/texts/pt-br";
import { DefaultLogo, Swiper } from "../../components/index";
import Styles from "./EsqueceuSenhaStyles";

import { hooks, SessionStorage } from "../../utils";
import StepEmail from "./Steps/StepEmail";
import StepCode from "./Steps/StepCode";
import { customModal } from "../../components/modals/utils";
import StepPassword from "./Steps/StepPassword";
import { api } from "../../services";

function EsqueceuSenha() {
  const texts = ptBr.login;
  //const version = packageJson.version;
  const navigate = useNavigate();
  const { loading, call } = hooks.useRequest();
  const [formData, setFormData] = useState<any>({});
  const Submit = async (data) => {};

  const FinishStepOne = async (data, Callback) => {
    setFormData({ ...formData, ...data });
    call(null, api.sendRecoveryPasswordRequest(data), (response) => {
      if (response.ok) {
        Callback();
      }
    });
  };

  const FinishStepTwo = async (data, Callback, errorCallback) => {
    setFormData({ ...formData, ...data });
    call(null, api.recoveryPasswordValidate(data), (response) => {
      if (response.ok) {
        Callback();
      } else {
        errorCallback();
      }
    });
  };

  const FinishStepThree = async (data) => {
    call(null, api.sendRecoveryPasswordNewPassword(data), (response) => {
      if (response.ok) {
        customModal.setInfos(
          "Nova Senha Redefinida",
          [
            "Sua nova senha foi redefinida com sucesso e você já pode utilizá-la para acessar sua conta!",
          ],
          {
            onClick: () => {
              navigate("/");
              customModal.close();
              SessionStorage.removeItem("token");
            },
            label: "Ir para Login",
          },
          null,
          null,
          true
        );
      }
    });
  };
  const CancelSteps = async (callback) => {
    customModal.setInfos(
      "Cancelar Recuperação de Senha",
      [
        "Ao cancelar o processo de recuperação de senha, a mesma não será redefinida e continuará como a antiga.",
        "Cancelar recuperação?",
      ],
      {
        onClick: () => {
          customModal.close();
          callback();
        },
        label: "Sim, Cancelar",
      },
      {
        onClick: () => {
          customModal.close();
        },
        label: "Voltar",
      },
      null,
      true
    );
  };
  return (
    <Styles.ImageBG>
      <Swiper
        screens={[
          ({ goNext }) => (
            <StepEmail
              loading={loading}
              onSubmit={(data) => {
                FinishStepOne(data, goNext);
              }}
              goBack={() => navigate("/")}
            />
          ),
          ({ goNext, goPrevious }) => (
            <StepCode
              loadingOut={loading}
              onSubmit={(data, errorCallback) => {
                FinishStepTwo({ ...formData, ...data }, goNext, errorCallback);
              }}
              goBack={() => CancelSteps(() => navigate("/"))}
              sendCode={(Callback) => {
                call(
                  null,
                  api.sendRecoveryPasswordRequest(formData),
                  (response) => {
                    if (response.ok) {
                      Callback();
                    }
                  }
                );
              }}
            />
          ),
          ({ goNext, goPrevious }) => (
            <StepPassword
              onSubmit={(data) => {
                FinishStepThree({ ...formData, password: data.password });
              }}
              goBack={() =>
                CancelSteps(() => {
                  navigate("/");
                })
              }
              loading={loading}
            />
          ),
        ]}
      />
    </Styles.ImageBG>
  );
}

export default EsqueceuSenha;
