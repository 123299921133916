import React from "react";
import styled from "styled-components";
import { Images, SVG } from "../../config";

const LogoContainer = styled.div(({ maxWidth = 154 }) => {
  return {
    width: "100%",
    height: "auto",
    flexShrink: 0,
    maxWidth: maxWidth,
    display: "flex",
  };
});

type DefaultLogoProps = {
  white?: boolean;
  maxWidth?: string | number;
};

function DefaultLogo({ white, maxWidth }: DefaultLogoProps) {
  return (
    <LogoContainer maxWidth={maxWidth}>
      {white ? (
        <img
          src={Images.LogoWhite}
          style={{
            width: "100%",
          }}
        />
      ) : (
        <img
          src={Images.LogoGray}
          style={{
            width: "100%",
          }}
        />
      )}
    </LogoContainer>
  );
}

export default DefaultLogo;
