import { Backdrop, Modal, Paper } from "@material-ui/core";
import styled from "styled-components";
import { Spacing } from "../../../config";
import CloseIcon from "@material-ui/icons/Close";
import FontStyles from "../../styles/fontStyles";
import { ButtonContained, ButtonText } from "../..";

const StyledBackdrop = styled(Backdrop)({
  backdropFilter: "blur(5px)",
  WebkitBackdropFilter: "blur(5px)",
  backgroundColor: "#00000020",
});

const StyledModal = styled(Modal)({
  outlineWidth: 0,
  borderWidth: 0,
  overflow: "hidden",
  ":focus": {},
});

const Container = styled(Paper)(({ theme, noMaxWidth }) => {
  const { spacing, palette: colors, breakpoints } = theme;
  return {
    "&&.MuiPaper-root": {
      position: "absolute",
      width: "auto",
      maxWidth: noMaxWidth ? "60vw" : 523,
      maxHeight: "90vh",
      height: "fit-content",
      paddingBlock: Spacing(3),
      "-webkit-font-smoothing": "subpixel-antialiased",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: "auto",

      outline: "none",
      backgroundColor: colors.text.primary,
      overflowY: "auto",
      [breakpoints.down("md")]: {
        maxWidth: "100%",
        width: "clamp(310px, 95%, 700px)",
      },
    },
  };
});

const ContentContainer = styled.div(({ theme }) => {
  return {
    overflow: "hidden",
  };
});

const HeaderContainer = styled.div(({ theme, noExit }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingInline: spacing(2.5),
    paddingBottom: Spacing(3.5),
    // marginTop: noExit ? spacing(1.5) : spacing(0.5),
  };
});

const Close = styled(CloseIcon)(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    color: colors.carbon,
    width: spacing(3),
    height: spacing(3),
  };
});

const TextContainer = styled.div(({ theme }) => {
  return {
    color: "white",
    paddingBottom: Spacing(3.5),

    textAlign: "center",
    [`@media only screen and (max-height: 700px)`]: {
      padding: 0,
      margin: 0,
    },
  };
});
const PaddingModal = styled.div(({ theme }) => {
  return {
    paddingInline: Spacing(2.5),
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "flex-start",
    alignItems: "center",
  };
});
const ContentText = styled.p(({ theme, first }) => {
  const { palette: colors, spacing } = theme;
  return {
    ...FontStyles.regular14,
    color: colors.text.secondary,
    textAlign: "left",
    paddingInline: spacing(3.12),
    marginBottom: spacing(1.25),
    marginTop: spacing(0),
  };
});
const Title = styled.p(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.medium16,
    color: colors.text.secondary,
    fill: colors.text.secondary,
    margin: 0,
    padding: 0,
    display: "flex",
    alignItems: "center",
    gap: "8px",
  };
});
const FooterContainer = styled.div(({ theme }) => {
  return {
    marginTop: Spacing(1),
    paddingInline: Spacing(3.5),
    display: "flex",

    justifyContent: "flex-start",
    alignItems: "center",
  };
});

const ConfirmButton = styled(ButtonContained)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    "&&.MuiButton-root": {
      width: "auto",
      marginLeft: spacing(2),
      backgroundColor: colors.text.secondary,
      color: colors.text.primary,
      ...FontStyles.medium14,
      "&:hover": {
        backgroundColor: colors.primary.contrastText,
      },
    },
  };
});

const ExitButton = styled(ButtonText)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    "&&.MuiButton-root": {
      textTransform: "capitalize",
      color: colors.text.secondary,
      ...FontStyles.medium14,
    },
  };
});

const Styles = {
  StyledBackdrop,
  StyledModal,
  Container,
  ContentContainer,
  HeaderContainer,
  Close,
  TextContainer,
  PaddingModal,
  ContentText,
  Title,
  FooterContainer,
  ConfirmButton,
  ExitButton,
};

export default Styles;
