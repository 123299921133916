import React from "react";
import { InputPassword } from "../../../components/index";
import { Grid } from "@material-ui/core";
import Styles from "../styles";
import { FormFull } from "form-full";
import { customModal } from "../../../components/modals/utils";
import ptBr from "../../../config/texts/pt-br";



function NewPasswordRegistry({ onSubmit }: { onSubmit?: any }) {
    const texts = ptBr.forgetPassword;
    const [buttonDisabled, setButton] = React.useState(true);
    const [error, setError] = React.useState(false);
    const validatePassword = (data) => {
        if (data.newPassword === data.confirmNewPassword) {
            setError(false);
            onSubmit({ password: data.confirmNewPassword });
        } else {
            setError(true);
        }
    };
    return (
        <>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    minWidth: "clamp(300px, 20vw, 650px)",
                    paddingInline: "25px",
                }}
            >
                <FormFull onSubmit={validatePassword}>
                    <Grid alignItems="flex-end" container spacing={2}>

                        <Grid item xs={12} md={12} lg={12}>
                            <InputPassword
                                white
                                name="newPassword"
                                label={texts.password}
                                placeholder="Nova senha"
                                required={texts.passwordMessage}
                                onChange={() => {
                                    setButton(false);
                                    setError(false);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={12}>
                            <InputPassword
                                white
                                name="confirmNewPassword"
                                label={"CONFIRMAÇÃO DE NOVA SENHA"}
                                placeholder="Confirmação de nova senha"
                                required={texts.passwordMessage}
                                onChange={() => {
                                    setButton(false);
                                    setError(false);
                                }}

                            />
                        </Grid>
                        {error && (
                            <Styles.ErrorMessage>
                                As senhas não correspondem.
                            </Styles.ErrorMessage>
                        )}

                    </Grid>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            gap: "12px",
                            marginTop: "40px",
                            width: "100%",
                        }}
                    >
                        <Styles.CancelButton
                            fullWidth={false}
                            onClick={() => {
                                customModal.close();
                            }}
                        >
                            Cancelar
                        </Styles.CancelButton>
                        <Styles.RegitryButton fullWidth={false} action="submit">
                            Confirmar Nova Senha
                        </Styles.RegitryButton>
                    </div>
                </FormFull>
            </div>
        </>
    );

}

export default NewPasswordRegistry