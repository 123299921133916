import React from "react";
import Styles from "../styles";
import PropertyCard from "./Card";

export default function Column({
  dataColumn,
  cards,
  marginLeft,
  onStatusChange
}) {
  return (
    <Styles.ContainerColumn
      marginLeft={marginLeft}
      backgroundColor={dataColumn.color}
      border={dataColumn.border}
    >
      <Styles.ColumnHeader>
        <Styles.ColumnTitle titleColor={dataColumn.titleColor}>
          {dataColumn?.icon && (
            <dataColumn.icon alt="" style={{ marginRight: "5px" }} />
          )}
          {dataColumn.title}
        </Styles.ColumnTitle>
      </Styles.ColumnHeader>
      <Styles.ListCard>
        {cards.map((cardData, index) => {
          return (
            <PropertyCard
              data={cardData}
              key={index}
              onStatusChange={onStatusChange}
            />
          );
        })}
      </Styles.ListCard>
    </Styles.ContainerColumn>
  );
}
