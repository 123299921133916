import styled from "styled-components";
import { ButtonContained, ButtonText, FontStyles } from "../../../components";

import { Spacing } from "../../../config";
import { FormHelperText } from "@material-ui/core";

const ImageBG = styled.div(({ theme }) => {
  const { palette: colors } = theme;

  return {
    backgroundColor: colors.action.selected,
    display: "flex",
    width: "100%",
    height: "100vh",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };
});

const Content = styled.div(({ theme }) => {
  const { spacing, palette: colors, breakpoints } = theme;
  return {
    display: "flex",
    position: "relative",

    justifyContent: "space-between",
    flexDirection: "column",
    padding: spacing(6.25),
    overflowX: "auto",
    textAlign: "left",
    backgroundColor: colors.background.paper,
    borderRadius: spacing(1.1),

    width: spacing(72.5),
    maxHeight: spacing(85.5),
    height: "85%",
    [breakpoints.down("md")]: {
      width: "95%",
    },
  };
});

const Header = styled.div(() => {
  return {
    width: "fit-content",
    marginBottom: Spacing(5),
    display: "flex",
    alignSelf: "center",
    justifyContent: "flex-start",
  };
});

const Title = styled.p(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    color: colors.action.selected,
    margin: 0,
    textAlign: "left",
    ...FontStyles.bold18,
  };
});

const Subtitle = styled.p(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    color: colors.text.primary,
    ...FontStyles.regular14,
    margin: 0,
    paddingBottom: Spacing(3),
  };
});

const CTGLogo = styled.img(() => {
  return {
    width: Spacing(17),
    height: "auto",
  };
});

const ForgotPasswordButton = styled.p(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    textAlign: "right",
    ...FontStyles.regular14,
    color: colors.action.selected,
    textDecoration: "underline",
    marginBottom: Spacing(4),
    cursor: "pointer",
  };
});

const Version = styled.div(() => {
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
});

const ButtonContainer = styled.div(() => {
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
  };
});

const P = styled.p(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    ...FontStyles.light16,
    color: colors.text.secondary,
    margin: 0,
    marginLeft: spacing(2),
    padding: 0,
  };
});

const EnterButton = styled(ButtonContained)(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    "&&.MuiButton-root": {
      backgroundColor: colors.action.selected,
      marginBottom: spacing(1),
    },
  };
});

const RegitryButton = styled(ButtonContained)(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    "&&.MuiButton-root": {
      backgroundColor: "white",
      color: colors.text.primary,
      marginTop: 0,
      ...FontStyles.medium14,

      "&:hover": {
        backgroundColor: colors.primary.contrastText,
      },

      "& .MuiButton-label": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  };
});

const CancelButton = styled(ButtonText)(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    "&&.MuiButton-root": {
      marginTop: 0,
      ...FontStyles.medium14,
      color: "white",
      paddingInline: spacing(2),
      "& .MuiButton-label": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  };
});

const ErrorMessage = styled(FormHelperText)(() => {
  return {
    "&&.MuiFormHelperText-root": {
      color: "red",
      minHeight: Spacing(2.5),
    },
  };
});

const InputBox = styled.div(({ $Image }) => {
  return {
    marginBottom: Spacing(4.5),
  };
});

const Styles = {
  Content,
  Title,
  Subtitle,
  Header,
  CTGLogo,
  ImageBG,
  ForgotPasswordButton,
  Version,
  ButtonContainer,
  P,
  EnterButton,
  RegitryButton,
  CancelButton,
  ErrorMessage,
  InputBox
};

export default Styles;
