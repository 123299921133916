import React, { useEffect } from "react";

import { CustomText, FontStyles } from "../../../components/index";
import Styles from "../styles";
import { FormFull } from "form-full";
import { hooks } from "../../../utils";

import { fonts, Theme } from "../../../config";

import { customModal } from "../../../components/modals/utils";

import { api } from "../../../services";

import ReactCodeInput from "react-code-input";
import { Check, Mail } from "@material-ui/icons";
import alerts from "../../../utils/alerts";
import { useMediaQuery } from "@material-ui/core";

function ModalAprove({
  reload,
  item,
  page,
  email
}: {
  reload: any;
  item: any;
  page: any;
  email?: boolean;
}) {
  const [value, setValue] = React.useState<any>("");
  const { loading, call } = hooks.useRequest();
  const inputRef = React.useRef<any>();
  useEffect(() => {
    if (null !== inputRef.current) {
      document
        .querySelectorAll("input")
        .forEach((node: HTMLInputElement) =>
          node.setAttribute("placeholder", "-")
        );
    }
  });

  const aproveItem = (reservation) => {
    if (email) {
      call(
        null,
        api.postSendEmail({
          reservationId: reservation.currentReservationId
            ? reservation.currentReservationId
            : reservation.id
        }),
        async (response) => {
          if (response.ok) {
            customModal.close();
            if (response.ok) {
              customModal.setInfos(
                "E-mail Enviado",
                ["E-mail reenviado com sucesso!"],
                {
                  label: "Ok, Entendi",
                  onClick: () => {
                    customModal.close();
                  }
                },
                null,
                null,
                null,
                null,
                <Mail />
              );
              reload(page);
            }
          }
        }
      );
    } else {
      call(
        null,
        api.patchReservationApprove({
          reservationId: reservation.id
        }),
        (response) => {
          if (response.ok) {
            customModal.close();
            reload(page);
            customModal.setInfos(
              "Aprovada Reserva",
              ["Reserva aprovada com sucesso!"],
              {
                label: "Ok, Entendi",
                onClick: () => {
                  customModal.close();
                }
              },
              null,
              null,
              null,
              null,
              <Check />
            );
          }
        }
      );
    }
  };

  const isMobile = useMediaQuery(Theme.Light.breakpoints.down("md"));

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          minWidth: "clamp(300px, 30vw, 511px)",
          paddingInline: "25px"
        }}
      >
        <FormFull onSubmit={(data) => {}}>
          <CustomText
            fontFamily={fonts.regular}
            fontSize={14 / 8}
            textColor="#fff"
          >
            {email
              ? "Ao reenviar e-mail para este usuário, o sistema estará encaminhando a solicitação dos dados, caso o mesmo ainda não tenha realizado o envio destes. Também será enviado um novo token que substituirá a chave atual. Deseja continuar?"
              : "Para continuar o processo de provação desta reserva, favor informe o token de 6 dígitos encaminhado para o hóspede deste imóvel:"}
          </CustomText>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "12px",
              marginTop: "40px",
              width: "100%"
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "12px"
              }}
            >
              <Styles.CancelButton
                loading={loading}
                disabled={loading}
                onClick={() => {
                  customModal.close();
                }}
                fullWidth={false}
              >
                Cancelar
              </Styles.CancelButton>
              <Styles.RegitryButton
                fullWidth={false}
                loading={loading}
                disabled={loading}
                onClick={() => {
                  aproveItem(item);
                }}
              >
                {email ? "Reenviar E-mail" : "Aprovar Reserva"}
              </Styles.RegitryButton>
            </div>
          </div>
        </FormFull>
      </div>
    </>
  );
}

export default ModalAprove;
