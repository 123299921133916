import React from "react";

import ptBr from "../../../config/texts/pt-br";
import {
  ButtonText,
  CustomText,
  Input,
  InputAutocomplete,
  InputDate
} from "../../../components/index";
import Styles from "../styles";
import { FormFull } from "form-full";

import { Grid } from "@material-ui/core";

function Filters({ onSubmit, loading }) {
  const texts = ptBr.login;

  const teamsOptions = [
    { label: "Limpeza", value: "Cleaning" },
    { label: "Manutenção", value: "Maintenance" }
  ];

  return (
    <FormFull onSubmit={(data) => onSubmit(data)}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={8} lg={4}>
          <Input name="fullName" label="Nome Completo" />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Input name="email" label="E-mail" />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <InputAutocomplete
            name="team"
            label="EQUIPE PERTENCENTE"
            options={teamsOptions}
          />
        </Grid>
      </Grid>
      <Styles.ButtonContainer>
        <Styles.Clear
          loading={loading}
          disabled={loading}
          fullWidth={false}
          action="clear"
        >
          Limpar
        </Styles.Clear>
        <Styles.Submit
          loading={loading}
          disabled={loading}
          fullWidth={false}
          action="submit"
        >
          Aplicar Filtros
        </Styles.Submit>
      </Styles.ButtonContainer>
    </FormFull>
  );
}

export default Filters;
