import React, { useState } from "react";
import Styles from "./styles/Styles";
import { fonts, SVG } from "../../config";
import { Add, ExpandMore } from "@material-ui/icons";
import { customModal } from "../../components/modals/utils";
import ModalRegistry from "./components/ModalRegistry";
import { HiPencil } from "react-icons/hi2";
import {
    ButtonMenu,
    ContentFeedback,
    CustomText,
    Pagination,
    Table,
} from "../../components";
import { BsPersonFillSlash } from "react-icons/bs";
import Filters from "./components/Filters";

export default function RegisterGovernance() {
    const [page, setPage] = React.useState<number>(1);
    const [totalPages, setTotalPages] = useState(0);

    const MOCK_DATA = [
        {
            id: 1,
            fullName: "Leandro Bittencourt",
            email: "email01@exemplo.com.br",
        },
        {
            id: 2,
            fullName: "Taynara Soares",
            email: "exemplo@email.com.br",
        },
        {
            id: 3,
            fullName: "Leandro Bittencourt",
            email: "email01@exemplo.com.br",
        },
        {
            id: 4,
            fullName: "Taynara Soares",
            email: "exemplo@email.com.br",
        },
        {
            id: 5,
            fullName: "Leandro Bittencourt",
            email: "email01@exemplo.com.br",
        },
    ];

    const openRegisterModal = () => {
        customModal.setInfos(
            "Cadastrar Governança",
            [],
            null,
            null,
            <ModalRegistry />,
            false,
            true,
            <SVG.Maintenance />
        );
    };

    const openEditModal = (data) => {
        customModal.setInfos(
            "Editar Dados do Governança",
            [],
            null,
            null,
            <ModalRegistry data={data} onSubmit={data} />,
            false,
            true,
            <HiPencil />
        );
    };

    const RevokeModalAccess = (id: string) => {
        customModal.setInfos(
            "Revogar Acesso",
            [
                "Continuar e revogar acesso desta governança?",
            ],
            {
                label: "Sim, Revogar",
                onClick: () => {
                    customModal.close();
                },
            },
            {
                label: "Voltar",
                onClick: () => {
                    customModal.close();
                },
            },
            null,
            null,
            null,
            <BsPersonFillSlash />
        );
    };

    const getButtons = (item: any): any[] => {
        return [
            {
                label: "Editar Dados do Governança",
                icon: HiPencil,
                onClick: () => openEditModal(item),
            },
            {
                label: "Revogar Acesso",
                icon: BsPersonFillSlash,
                onClick: () => RevokeModalAccess(item.id),
            },
        ];
    };

    return (
        <>
            <Styles.PageNameContainer>
                <Styles.PageNameContent>
                    <CustomText fontSize={2} textColor="#FFFFFF" fontFamily={fonts.bold}>
                        Cadastrar Governança
                    </CustomText>
                    <CustomText
                        style={{ paddingRight: "8px" }}
                        fontSize={1.75}
                        textColor="#FFFFFF"
                        fontFamily={fonts.regular}
                    >
                        Alguns dados básicos serão solicitados para o cadastro de uma nova
                        governança.
                    </CustomText>
                </Styles.PageNameContent>
                <Styles.RegitryButton
                    startIcon={<Add />}
                    fullWidth={false}
                    onClick={() => openRegisterModal()}
                >
                    Cadastrar
                </Styles.RegitryButton>
            </Styles.PageNameContainer>

            <Styles.ContainerFilter>
                <CustomText
                    style={{ paddingBottom: "8px" }}
                    fontSize={2}
                    textColor="#4D585A"
                    fontFamily={fonts.bold}
                >
                    Filtrar Governanças Cadastradas
                </CustomText>
                <Filters onSubmit={(data) => console.log(data)} loading={false} />
            </Styles.ContainerFilter>

            <Styles.Container>
                <CustomText
                    style={{ paddingBottom: "8px" }}
                    fontSize={2}
                    textColor="#4D585A"
                    fontFamily={fonts.bold}
                >
                    Lista de Governanças Cadastradas
                </CustomText>
                <ContentFeedback data={MOCK_DATA} loading={false}>
                    <Table
                        lastAlign={false}
                        data={MOCK_DATA}
                        renderItemColumns={(item) => [
                            item.fullName,
                            item.email,
                            null,
                            null,
                            <ButtonMenu endIcon={<ExpandMore />} buttons={getButtons(item)}>
                                Opções
                            </ButtonMenu>,
                        ]}
                        headers={{
                            table: ["NOME COMPLETO", "E-MAIL", "", "", "AÇÃO"],
                            keys: [],
                        }}
                    />
                    <Pagination
                        style={{ padding: "16px 20px", paddingTop: 0 }}
                        page={page}
                        totalPages={totalPages}
                        setPage={(number) => setPage(number)}
                    />
                </ContentFeedback>
            </Styles.Container>
        </>
    );
}
