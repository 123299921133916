import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import ptBr from "../../config/texts/pt-br";
import {
  ButtonContained,
  CustomText,
  DefaultLogo,
  InputEmail,
  InputPassword,
} from "../../components/index";
import Styles from "./styles";
import { FormFull } from "form-full";
import { validations } from "../../utils";
import { hooks, SessionStorage } from "../../utils";
import { StorageContext } from "../../contexts/StorageContext";

import { api } from "../../services";
import { paths } from "../../navigation/navigate";
import { customModal } from "../../components/modals/utils";
import NewPasswordRegistry from "./components/NewPasswordRegistry";
import { FaKey } from "react-icons/fa6";

function Login() {
  const texts = ptBr.login;

  const openNewPasswordModal = () => {
    customModal.setInfos(
      "Alteração de Senha",
      [],
      null,
      null,
      <NewPasswordRegistry onSubmit={() => null} />,
      null,
      null,
      <FaKey />
    );
  };

  const navigate = useNavigate();
  const { loading, call } = hooks.useRequest();
  const { setUserData, setIsLogged } = useContext<any>(StorageContext);
  //utilizado para lógica de verificação se é primeiro usuario (apagar depois)
  const [isFirstLogin, setFirstLogin] = useState<boolean>(true);

  const Submit = async (data) => {
    data.username = (data.username + "").toLocaleLowerCase();
    call(null, api.getToken(data), (response) => {
      if (response.ok) {
        setIsLogged(true);

        call(null, api.getUserData(), (response) => {
          if (response.ok) {
            SessionStorage.setItem("userData", response?.data);
            setUserData(response.data);
            /*Implementar integração de primeiro usuário e finalizar 
            lógica se é primeiro usuário e se é gestor ou proprietário*/
            if (!isFirstLogin) {
              openNewPasswordModal();
            } else {
              navigate(paths.myProperties);
            }
          }
        });
      }
    });
  };

  const [buttonDisabled, setButton] = React.useState(true);

  return (
    <Styles.ImageBG>
      <FormFull onSubmit={Submit}>
        <Styles.Content>
          <Styles.Header>
            <DefaultLogo maxWidth="126px" />
          </Styles.Header>
          <Styles.Title>{texts.title}</Styles.Title>
          <Styles.Subtitle>{texts.subtitle}</Styles.Subtitle>
          <InputEmail
            name="username"
            label={texts.user}
            required={texts.userMessage}
            validation={validations.isEmailValid}
            placeholder="email@exemplo.com.br"
          />
          <InputPassword
            name="password"
            label={texts.password}
            required={texts.passwordMessage}
            validation={validations.isPassword}
            onChange={() => {
              setButton(false);
            }}
            placeholder="Digite sua senha"
          />

          <Styles.ButtonContainer>
            <Styles.ForgotPasswordButton
              onClick={() => navigate(paths.forgetPassword)}
            >
              {texts.forgotPass}
            </Styles.ForgotPasswordButton>
          </Styles.ButtonContainer>
          <Styles.EnterButton
            name="submit"
            loading={loading}
            disabled={buttonDisabled}
            feedback={true}
            action="submit"
            type="submit"
            label={texts.enter}
          />
          <CustomText style={{ justifyContent: "center" }}>
            {process.env.REACT_APP_ENV + " v"}
            {process.env.REACT_APP_VERSION}
          </CustomText>
        </Styles.Content>
      </FormFull>
    </Styles.ImageBG>
  );
}

export default Login;
