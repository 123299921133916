import React from "react";

import ptBr from "../../../config/texts/pt-br";

import {
  ButtonContained,
  ButtonOutlined,
  ButtonText,
  DefaultLogo
} from "../../../components";
import Styles from "../EsqueceuSenhaStyles";
import { FormFull } from "form-full";
import { hooks } from "../../../utils";

import fonts from "../../../config/fonts";
import ReactCodeInput from "react-code-input";
import { Spacing, Theme } from "../../../config";
import { FontStyles } from "../../../components";
import Countdown from "./Countdown";
import { useMediaQuery } from "@material-ui/core";

interface PageProps {
  goBack: () => void;
  onSubmit: (data: any) => void;
  loadingOut: () => void;
  sendCode?: () => void;
}

function StepCode({ onSubmit, goBack, loadingOut, sendCode }) {
  const texts = ptBr.forgetPassword;

  const [ref, setRef] = React.useState<any>(null);
  const [resetCutdown, setResetCutdown] = React.useState<any>(() => {});

  const inputRef = React.useRef<any>();
  const [value, setValue] = React.useState("");

  const { loading, call } = hooks.useRequest();

  const sendTokenData = () => {
    if (inputRef?.current) {
      const code = inputRef.current.state.value;
      onSubmit({ code: code }, () => {
        setValue("");
        if (inputRef.current.textInput[0])
          inputRef.current.textInput[0].focus();
        inputRef.current.state.input[0] = "";
        inputRef.current.state.input[1] = "";
        inputRef.current.state.input[2] = "";
        inputRef.current.state.input[3] = "";
        inputRef.current.state.input[4] = "";

        resetCutdown?.callback();
      });
    }
  };
  const isMobile = useMediaQuery(Theme.Light.breakpoints.down("md"));
  return (
    <FormFull formRef={setRef} onSubmit={() => {}}>
      <Styles.Content>
        <Styles.Header>
          <DefaultLogo maxWidth="126px" />
        </Styles.Header>
        <div>
          <Styles.Title>{texts.titleStepTwo}</Styles.Title>
          <Styles.Subtitle> {texts.subTitle[1]}</Styles.Subtitle>
        </div>

        <div>
          <Styles.MarginBottom center>
            <Styles.LabelContainer>
              <Styles.SubToken>TOKEN*:</Styles.SubToken>
              <ReactCodeInput
                inputStyle={
                  isMobile
                    ? {
                        fontFamily: FontStyles.medium12.fontSize,
                        margin: "4px",
                        MozAppearance: "textfield",
                        width: "46px",
                        fontSize: "16px",
                        height: "40px",
                        textAlign: "center",

                        color: "#6A6A6A",
                        border: "1px solid #626366",
                        borderRadius: Spacing(0.8)
                      }
                    : {
                        fontFamily: FontStyles.medium12.fontSize,
                        margin: "4px",
                        MozAppearance: "textfield",
                        width: "56px",

                        height: "50px",
                        textAlign: "center",
                        fontSize: "22px",
                        color: "#6A6A6A",
                        border: "1px solid #626366",
                        borderRadius: Spacing(0.8)
                      }
                }
                onChange={(a) => {
                  setValue(a);
                }}
                value={value}
                ref={inputRef}
                forceUppercase
                type="text"
                fields={5}
                name={"code"}
                inputMode={"latin"}
              />
            </Styles.LabelContainer>
          </Styles.MarginBottom>
        </div>
        <div>
          <Styles.EnterButton
            name="continue"
            loading={loading || loadingOut}
            feedback={true}
            disabled={loading || value.length < 5 ? true : false}
            onClick={() => sendTokenData()}
            label={texts.continue[1]}
          />
          <Styles.BackButton onClick={() => goBack()}>
            {texts.cancel}
          </Styles.BackButton>
          <Countdown
            setReset={setResetCutdown}
            loading={loading || loadingOut}
            setReSend={(callback) => {
              sendCode(callback);
            }}
          />
        </div>
      </Styles.Content>
    </FormFull>
  );
}

export default StepCode;
