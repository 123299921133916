import { CancelToken } from "apisauce";
import React, { useState, useEffect } from "react";
import { createContext } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../services";

import { hooks, SessionStorage } from "../utils";

export const StorageContext = createContext({});

export default function StorageProvider({ children }: any): JSX.Element {
  const [data, setData] = useState<any>({});
  const [isLogged, setIsLogged] = useState<boolean>(false);
  const [userData, setUserData] = useState<any>(null);
  const [cancelToken, setCancelToken] = useState<any>(CancelToken.source());
  const { call } = hooks.useRequest();

  const navigate = useNavigate();
  useEffect(() => {
    const token = SessionStorage.getItem("token");
    if (token) {
      const user = SessionStorage.getItem("userData");

      if (!user) {
        call(null, api.getUserData(), (response) => {
          if (response.ok) {
            SessionStorage.setItem("userData", response?.data);
            setUserData(response?.data);
            setIsLogged(true);
          }
        });
      } else {
        setIsLogged(true);
      }
    }
  }, [setIsLogged]);

  function logOut() {
    removeData("token");
    SessionStorage.removeItem("token");
    SessionStorage.removeItem("userData");
    setUserData(null);
    navigate("/");
    setIsLogged(false);
  }

  function addData(key?: any, value?: any) {
    setData((state?: any) => ({ ...state, [key]: value }));
  }

  function removeData(key?: any) {
    setData((state?: any) => {
      const newState = state;
      delete newState[key];
      return newState;
    });
  }

  function getData(key?: any) {
    return data[key];
  }

  return (
    <StorageContext.Provider
      value={{
        addData,
        removeData,
        getData,
        isLogged,
        setIsLogged,
        logOut,
        cancelToken,
        setCancelToken,
        userData,
        setUserData,
      }}
    >
      {children}
    </StorageContext.Provider>
  );
}
