import styled from "styled-components";
import { ButtonContained, ButtonText, FontStyles } from "../../../components";

const PageNameContainer = styled.div(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    backgroundColor: theme.palette.action.selected,
    padding: "16px 20px",
    borderRadius: "5px",

    width: "100%",
    marginBottom: theme.spacing(1.5),
    height: "fit-content",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-end",
      gap: "8px",
    },
  };
});

const PageNameContent = styled.div(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.75),
    paddingInline: "20px",
    backgroundColor: theme.palette.action.selected,
  };
});

const RegitryButton = styled(ButtonContained)(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    "&&.MuiButton-root": {
      backgroundColor: "white",
      color: colors.text.primary,
      marginTop: 0,
      ...FontStyles.medium14,

      "&:hover": {
        backgroundColor: colors.primary.contrastText,
      },

      "& .MuiButton-label": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  };
});

const ContainerFilter = styled.div(({ theme, noLine }) => {
  return {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    padding: "16px 20px",
    paddingInline: noLine && 0,
    borderRadius: "5px",

    width: "100%",
    marginBottom: theme.spacing(1.5),
    height: "fit-content",
  };
});

const ButtonContainer = styled.div(({ theme }) => {
  return {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "row",
    gap: theme.spacing(1),
  };
});

const Submit = styled(ButtonContained)(({ theme }) => {
  return {
    "&&.MuiButton-root": {
      backgroundColor: theme.palette.action.selected,
      ...FontStyles.semibold12,
      paddingInline: theme.spacing(2.5),
      paddingBlock: theme.spacing(1.9),
    },
  };
});
const Clear = styled(ButtonText)(({ theme }) => {
  return {
    "&&.MuiButton-root": {
      color: theme.palette.action.selected,
      ...FontStyles.semibold12,
      paddingInline: theme.spacing(2.5),
      paddingBlock: theme.spacing(1.9),
    },
  };
});

const Container = styled.div(({ theme, noLine }) => {
  return {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    padding: "16px 20px",
    paddingInline: noLine && 0,
    borderRadius: "5px",

    width: "100%",
    marginBottom: theme.spacing(1.5),
    height: "fit-content",
  };
});

const CancelButton = styled(ButtonText)(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    "&&.MuiButton-root": {
      marginTop: 0,
      ...FontStyles.medium14,
      color: "white",
      paddingInline: spacing(2),
      "& .MuiButton-label": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  };
});

const ContainerList = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    backgroundColor: "#F2F2F2",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    margin: spacing(2),
  };
});
const Styles = {
  PageNameContainer,
  PageNameContent,
  RegitryButton,
  ContainerFilter,
  ButtonContainer,
  Submit,
  Clear,
  Container,
  CancelButton,
  ContainerList,
};

export default Styles;
