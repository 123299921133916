import React from "react";
import { FormFull } from "form-full";
import { masks, validations } from "../../../utils";
import Styles from "../styles/Styles";
import { Grid } from "@material-ui/core";
import {
    CustomText,
    FontStyles,
    Input,
    InputDate,
} from "../../../components/index";
import { fonts, Spacing } from "../../../config";
import { AccessTime } from "@material-ui/icons";

function Filters({ onSubmit, loading }) {
    return (
        <FormFull onSubmit={(data) => onSubmit(data)}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={8} lg={2}>
                    <Input
                        name="responsibleName"
                        label="NOME RESPONSÁVEL"
                        placeholder="Nome Responsável"
                        disableError
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                    <Input
                        name="cpf"
                        label="Cpf"
                        placeholder="000.000.000-00"
                        mask={masks.CPF}
                        maskToSubmit={masks.removeNumberMask}
                        disableError
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                    <Input
                        name="email"
                        label="E-mail"
                        placeholder="E-mail"
                        disableError
                    />
                </Grid>
                <Grid container item xs={12} md={12} lg={3}>
                    <CustomText
                        fontFamily={fonts.semibold}
                        fontSize={1.8}
                        style={{ alignSelf: "center", marginBottom: "10px" }}
                    >
                        DATA
                    </CustomText>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: "10px",
                            width: "100%",
                        }}
                    >
                        <InputDate
                            onBlur={() => null}
                            onChange={() => null}
                            name="startDate"
                            customValidation={() => null}
                            minDate={new Date()}
                            disableError
                        />
                        <CustomText fontFamily={fonts.medium} fontSize={14 / 8}>
                            até
                        </CustomText>
                        <InputDate
                            minDate={() => null}
                            name="endDate"
                            customValidation={() => null}
                            onBlur={() => null}
                            disableError
                        />
                    </div>
                </Grid>
                <Grid item xs={6} md={6} lg={3}>
                    <CustomText
                        fontFamily={fonts.semibold}
                        fontSize={1.8}
                        style={{ alignSelf: "center" }}
                    >
                        HORÁRIO
                    </CustomText>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                            justifyContent: "space-between",
                            gap: "10px",
                            width: "100%",
                        }}
                    >
                        <Input
                            onBlur={() => null}
                            disableError
                            mask={masks.formatHour}
                            placeholder="00:00"
                            validation={validations.validateTimeRange}
                            name="startTime"
                            endAdornment={<AccessTime style={{ fontSize: Spacing(2) }} />}
                        />

                        <CustomText
                            fontFamily={fonts.medium}
                            fontSize={14 / 8}
                            style={{ paddingBottom: "16px" }}
                        >
                            até
                        </CustomText>

                        <Input
                            endAdornment={<AccessTime style={{ fontSize: Spacing(2) }} />}
                            disableError
                            mask={masks.formatHour}
                            placeholder="00:00"
                            validation={validations.validateTimeRange}
                            name="endTime"
                            onBlur={() => null}
                        />
                    </div>
                </Grid>
            </Grid>
            <Styles.ButtonContainer>
                <Styles.Clear
                    loading={loading}
                    disabled={loading}
                    fullWidth={false}
                    action="clear"
                >
                    Limpar
                </Styles.Clear>
                <Styles.Submit
                    loading={loading}
                    disabled={loading}
                    fullWidth={false}
                    action="submit"
                >
                    Aplicar Filtros
                </Styles.Submit>
            </Styles.ButtonContainer>
        </FormFull>
    );
}

export default Filters;
