import { paths } from "../navigation/navigate";
import { GroupAdd, HomeWork } from "@material-ui/icons";
import SVG from "./svg";

const IsProduction = process.env.REACT_APP_ENV === "PROD";

const pagesConfig = {
  notLogged: [
    {
      navigationId: 0,
      path: paths.login,
      name: "Login"
    },
    {
      navigationId: 1,
      path: paths.forgetPassword,
      name: "EsqueceuSenha"
    }
  ],
  logged: [
    {
      dontShow: true,
      pages: [
        {
          navigationId: 0,
          path: paths.myProperties,
          name: "MyProperties",
          title: "Meus Imóveis",
          working: true,
          icon: HomeWork,
          description:
            "Listagem de seus imóveis adquiridos e atualizados em tempo real"
        },
        {
          navigationId: 0,
          path: paths.login,
          name: "Home",
          working: true
        },
        {
          navigationId: 0,
          path: paths.reservation,
          name: "Reservation",
          title: "Reservas",
          working: true,
          icon: SVG.Check,
          description:
            "Solicitações e Histórico de reservas realizadas via Omnibees"
        },
        {
          navigationId: 0,
          path: paths.registryReservation,
          name: "RegistryReservation",
          title: "Cadastrar Reserva",
          working: true,
          icon: SVG.File,
          description: "Cadastro manual de reservas via e-mail de usuários"
        }
      ]
    },
    {
      dontShow: false,
      groupTitle: "PRINCIPAL",
      pages: [
        {
          navigationId: 0,
          path: paths.myProperties,
          name: "MyProperties",
          title: "Meus Imóveis",
          working: true,
          icon: HomeWork,
          description:
            "Listagem de seus imóveis adquiridos e atualizados em tempo real"
        },
        {
          navigationId: 0,
          path: paths.reservation,
          name: "Reservation",
          title: "Reservas",
          working: true,
          icon: SVG.Check,
          description:
            "Solicitações e Histórico de reservas realizadas via Omnibees"
        },
          /*
        !IsProduction && {
          navigationId: 0,
          path: paths.studioManagement,
          name: "StudioManagement",
          title: "Gestão de Studios",
          working: true,
          icon: SVG.windows,
          description: "Gerencie e edite o status atual de seus studios"
        },
        */
/*
        {
          navigationId: 0,
          //roles: ["",], //Adicionar perfil inquilino (integração)
          path: paths.steakhouseRental,
          name: "SteakhouseRentalPage",
          title: "Locação de Churrasqueira / Pub",
          working: true,
          icon: SVG.ClanedarHourIcon,
          description:
            "Agendamento de uso do espaço da churrasqueira / pub, busca e listagem dos horários reservados"
        },
        !IsProduction && {
          navigationId: 0,
          path: paths.cleaningAndMaintenance,
          name: "CleaningAndMaintenance",
          title: "Equipe Limpeza e Manutenção",
          roles: ["ROLE_ADMIN_HABITAT", "ROLE_ADMIN",],
          working: true,
          icon: SVG.Maintenance,
          description:
            "Realize a gestão dos funcionários responsáveis pela limpeza e manutenção dos studios"
        }*/
      ]
    },
    {
      dontShow: false,
      groupTitle: "CADASTRO",
      pages: [
        {
          navigationId: 0,
          path: paths.registryReservation,
          name: "RegistryReservation",
          title: "Cadastrar Reserva",
          working: true,
          icon: SVG.File,
          description: "Cadastro manual de reservas via e-mail de usuários"
        },
        {
          roles: ["ROLE_ADMIN_HABITAT",], // "ROLE_ADMIN_HABITAT" -> SuperAdmin | "ROLE_ADMIN" -> Admin | "ROLE_MANAGER" -> Gestor
          navigationId: 0,
          path: paths.registerOwners,
          name: "RegisterOwners",
          title: "Cadastrar Proprietário",
          working: true,
          icon: GroupAdd,
          description: "Cadastre proprietários de imóveis"
        },
        {
          roles: ["ROLE_ADMIN_HABITAT", "ROLE_ADMIN",],
          navigationId: 0,
          path: paths.mangerRegistry,
          name: "MangerRegistryPage",
          title: "Cadastrar Gestor",
          working: true,
          icon: SVG.addUser
        },
        /*
        {
          roles: ["ROLE_ADMIN_HABITAT"], // "ROLE_ADMIN_HABITAT" -> SuperAdmin | "ROLE_ADMIN" -> Admin | "ROLE_MANAGER" -> Gestor
          navigationId: 0,
          path: paths.registerGovernance,
          name: "RegisterGovernance",
          title: "Cadastrar Governança",
          working: true,
          icon: GroupAdd,
          description: "Cadastre a governança para proprietários de studios"
        },
        {
          //roles: ["",], //Adicionar perfil inquilino (integração),
          navigationId: 0,
          path: paths.registerServiceProvider,
          name: "RegisterServiceProvider",
          title: "Cadastrar Prestadora de Serviço",
          working: true,
          icon: SVG.Maintenance
        }*/
      ]
    },
    {
      dontShow: false,
      groupTitle: "SISTEMA",
      pages: [
        {
          navigationId: 0,
          path: null,
          name: "Exit",
          title: "Sair",
          working: true,
          icon: SVG.Exit
        },

        ,
      ]
    }
  ]
};
export default pagesConfig;
