import React, { useState } from "react";
import {
  CustomText,
  Input,
  InputRadios,
  InputTags
} from "../../../components/index";
import { Grid } from "@material-ui/core";
import { validations } from "../../../utils";
import { fonts, Spacing } from "../../../config";
import Styles from "../styles";
import { FormFull } from "form-full";
import { customModal } from "../../../components/modals/utils";

function ModalRevoke({
  data,

  onSubmit,
  placeList
}: {
  data: any;

  onSubmit: (id: string) => void;
  placeList?: any[];
}) {
  const [formRef, setFormRef] = useState<any>(null);
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          minWidth: "clamp(300px, 20vw, 650px)",
          paddingInline: "25px"
        }}
      >
        <CustomText
          style={{ marginBottom: "20px" }}
          fontFamily={fonts.bold}
          fontSize={14 / 8}
          textColor="#B2B2B2"
        >
          DADOS BÁSICOS DO PROPRIETÁRIO
        </CustomText>
        <Grid alignItems="flex-end" container spacing={1}>
          <Grid item xs={12} md={6} lg={6}>
            <CustomText
              textColor="#FFFFFF"
              fontFamily={fonts.semibold}
              fontSize={1.75}
            >
              Nome Completo
            </CustomText>
            <CustomText
              textColor="#FFFFFF"
              fontFamily={fonts.semibold}
              fontSize={1.75}
              style={{
                paddingBlock: Spacing(0.5),
                width: "100%",
                borderBottom: "1px solid white",
                marginBottom: Spacing(1)
              }}
            >
              {data?.name || ""}
            </CustomText>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <CustomText
              textColor="#FFFFFF"
              fontFamily={fonts.semibold}
              fontSize={1.75}
            >
              CPF
            </CustomText>
            <CustomText
              textColor="#FFFFFF"
              fontFamily={fonts.semibold}
              fontSize={1.75}
              style={{
                paddingBlock: Spacing(0.5),
                width: "100%",
                borderBottom: "1px solid white",
                marginBottom: Spacing(1)
              }}
            >
              {data?.cpf || ""}
            </CustomText>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <CustomText
              textColor="#FFFFFF"
              fontFamily={fonts.semibold}
              fontSize={1.75}
            >
              E-MAIL
            </CustomText>
            <CustomText
              textColor="#FFFFFF"
              fontFamily={fonts.semibold}
              fontSize={1.75}
              style={{
                paddingBlock: Spacing(0.5),
                width: "100%",
                borderBottom: "1px solid white",
                marginBottom: Spacing(1)
              }}
            >
              {data?.email || ""}
            </CustomText>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <CustomText
              textColor="#FFFFFF"
              fontFamily={fonts.semibold}
              fontSize={1.75}
            >
              STUDIOS VINCULADOS
            </CustomText>
            <view
              style={{
                paddingBlock: Spacing(0.5),
                display: "flex",
                width: "100%",
                gap: Spacing(1)
              }}
            >
              {data?.studios?.map((item) => {
                const existKey = placeList?.find((i) => i?.value === item);

                if (existKey) {
                  return (
                    <CustomText
                      textColor="#24272A"
                      fontFamily={fonts.semibold}
                      fontSize={1.75}
                      style={{
                        padding: "7px 10px",

                        backgroundColor: "#E0E0E0",
                        borderRadius: Spacing(1.75)
                      }}
                    >
                      {existKey?.label || ""}
                    </CustomText>
                  );
                }
              })}
            </view>
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "12px",
            paddingTop: "12px",
            width: "100%"
          }}
        >
          <Styles.CancelButton
            fullWidth={false}
            onClick={() => {
              customModal.close();
            }}
          >
            Voltar
          </Styles.CancelButton>

          <Styles.RegitryButton
            fullWidth={false}
            onClick={() => {
              onSubmit(data.id);
            }}
          >
            Sim, Revogar
          </Styles.RegitryButton>
        </div>
      </div>
    </>
  );
}

export default ModalRevoke;
