import React from "react";
import { CustomText, Input, InputRadios } from "../../../components/index";
import { Grid } from "@material-ui/core";
import { validations } from "../../../utils";
import { fonts } from "../../../config";
import Styles from "../styles";
import { FormFull } from "form-full";

function ModalRegistry({ employee }: { employee?: any }) {
  const [formRef, setFormRef] = React.useState<any>(null);

  const onSubmit = async (data: any) => {};

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          minWidth: "clamp(300px, 20vw, 650px)",
          paddingInline: "25px",
        }}
      >
        <FormFull formRef={setFormRef} onSubmit={onSubmit}>
          <CustomText
            style={{ marginBottom: "20px" }}
            fontFamily={fonts.bold}
            fontSize={14 / 8}
            textColor="#B2B2B2"
          >
            DADOS BÁSICOS DO FUNCIONÁRIO DE LIMPEZA/MANUTENÇÃO
          </CustomText>
          <Grid alignItems="flex-end" container spacing={1}>
            <Grid item xs={12} md={6} lg={6}>
              <Input
                disableError
                required={"*Campo Obrigatório"}
                name="fullName"
                defaultValue={employee?.name || ""}
                validation={validations.isValidFullname}
                label="Nome Completo"
                white
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Input
                disableError
                required={"*Campo Obrigatório"}
                validation={validations.isEmailValid}
                defaultValue={employee?.email || ""}
                name="email"
                label="E-Mail"
                white
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <InputRadios
                disableError
                required={"*Campo Obrigatório"}
                name="team"
                label="Equipe Pertencente"
                options={[
                  { value: "Limpeza", label: "Limpeza" },
                  { value: "Manutenção", label: "Manutenção" },
                ]}
                defaultValue={employee?.team || "Limpeza"}
                white
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "12px",
              paddingTop: "12px",
              width: "100%",
            }}
          >
            <Styles.CancelButton
              fullWidth={false}
              onClick={() => {
                // Lógica para fechar o modal
              }}
            >
              Cancelar
            </Styles.CancelButton>
            <Styles.RegitryButton
              fullWidth={false}
              onClick={async () => {
                const submitInfo = await formRef?.testErrorsAndReturnData();

                if (!submitInfo?.hasError) {
                  formRef?.submit();
                }
              }}
            >
              Confirmar {employee ? "Edição" : "Cadastro"}
            </Styles.RegitryButton>
          </div>
        </FormFull>
      </div>
    </>
  );
}

export default ModalRegistry;
