import styled from "styled-components";
import { ButtonContained, ButtonText, FontStyles } from "../../../components";

const Container = styled.div(({ theme, $dark, $row }) => {
  return {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    backgroundColor: $dark
      ? theme.palette.action.selected
      : theme.palette.background.paper,

    borderRadius: "5px",

    width: "100%",
    marginBottom: theme.spacing(1.5),
    height: "fit-content",
  };
});

const ContainerFilter = styled.div(({ theme, noLine }) => {
  return {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    padding: "16px 20px",
    paddingInline: noLine && 0,
    borderRadius: "5px",

    width: "100%",
    marginBottom: theme.spacing(1.5),
    height: "fit-content",
  };
});

const ContainerSecondary = styled.div(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    backgroundColor: theme.palette.action.selected,
    padding: "16px 20px",
    borderRadius: "5px",

    width: "100%",
    marginBottom: theme.spacing(1.5),
    height: "fit-content",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-end",
      gap: "8px",
    },
  };
});
const ButtonContainer = styled.div(({ theme }) => {
  return {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "row",
    gap: theme.spacing(1),
  };
});
const Submit = styled(ButtonContained)(({ theme }) => {
  return {
    "&&.MuiButton-root": {
      backgroundColor: theme.palette.action.selected,
      ...FontStyles.semibold12,
      paddingInline: theme.spacing(2.5),
      paddingBlock: theme.spacing(1.9),
    },
  };
});
const Clear = styled(ButtonText)(({ theme }) => {
  return {
    "&&.MuiButton-root": {
      color: theme.palette.action.selected,
      ...FontStyles.semibold12,
      paddingInline: theme.spacing(2.5),
      paddingBlock: theme.spacing(1.9),
    },
  };
});
const OnUse = styled.p(({ theme }) => {
  return {
    ...FontStyles.semibold12,
    paddingInline: theme.spacing(1.625),
    paddingBlock: theme.spacing(1),
    backgroundColor: "#4D585A",
    color: "white",
    borderRadius: theme.spacing(2),
    margin: 0,
  };
});

const RegitryContainer = styled.div(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.75),
  };
});

const RegitryButton = styled(ButtonContained)(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    "&&.MuiButton-root": {
      backgroundColor: "white",
      color: colors.text.primary,
      marginTop: 0,
      ...FontStyles.medium14,

      "&:hover": {
        backgroundColor: colors.primary.contrastText,
      },

      "& .MuiButton-label": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  };
});

const CancelButton = styled(ButtonText)(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    "&&.MuiButton-root": {
      marginTop: 0,
      ...FontStyles.medium14,
      color: "white",
      paddingInline: spacing(2),
      "& .MuiButton-label": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  };
});
const MarginBottom = styled.div(({ theme, center }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    width: "100%",

    justifyContent: center ? "center" : "flex-start",
    alignItems: center ? "center" : "flex-start",
    marginBottom: spacing(5),
  };
});
const LabelContainer = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    width: "fit-content",
    display: "flex",
    flexDirection: "column",
  };
});
const InputCodeLabel = styled.p(({ theme }) => {
  return {
    ...FontStyles.bold16,
    color: "black",
    fontSize: 12,
  };
});
const SubToken = styled.p(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    color: "white",
    ...FontStyles.semibold14,
    margin: 0,
    paddingTop: spacing(3.5),
    textAlign: "center",
  };
});

const Label = styled.p(({ theme, $witherror }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.semibold14,
    textTransform: "uppercase",
    color: $witherror ? colors.error.main : colors.text.secondary,
    transition: ".2s",
    pointerEvents: "none",
  };
});

const ContainerList = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    backgroundColor: "#F2F2F2",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    margin: spacing(2),
  };
});

const Styles = {
  Container,
  ContainerFilter,
  Submit,
  Clear,
  ButtonContainer,
  OnUse,
  RegitryContainer,
  RegitryButton,
  ContainerSecondary,
  CancelButton,
  MarginBottom,
  LabelContainer,
  InputCodeLabel,
  SubToken,
  Label,
  ContainerList,
};

export default Styles;
