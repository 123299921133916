import styled from "styled-components";
import { MdClose } from "react-icons/md";
import { SVG } from "../../../config";
import { FontStyles } from "../../../components";

const TagContainer = styled.div(({ theme }) => {
  const { breakpoints } = theme;

  return {
    display: "flex",
    alignSelf: "flex-end",
    cursor: "pointer",
    width: "fit-content",
    height: "35px",
    background: "#4d585a",
    borderRadius: "19px",
    alignItems: "center",
    padding: "0 10px",
    marginTop: "10px",
    transition:
      "background-color 0.3s" /* Adicionando transição para suavizar a mudança de cor */,
    "&:active": {
      background: "#3b4446" /* Nova cor quando o botão é pressionado */
    },

    [breakpoints.down("xl")]: {
      marginTop: 0
    }
  };
});

const Text = styled.span(({ theme }) => {
  const { breakpoints } = theme;

  return {
    ...FontStyles.semibold14,
    color: "white",
    margin: "0 5px 0 2px",
    textWrap: "nowrap",

    [breakpoints.down("xl")]: {
      ...FontStyles.semibold12
    }
  };
});

const MaintenanceTag = () => {
  return (
    <TagContainer>
      <MdClose color="white" size={20} />
      <Text>Em manutenção</Text>
      <SVG.Maintenance fill="white" />
    </TagContainer>
  );
};

export default MaintenanceTag;
