import React, { useEffect, useState } from "react";

import ptBr from "../../../config/texts/pt-br";
import {
  ButtonText,
  CustomText,
  Input,
  InputAutocomplete,
  InputDate,
} from "../../../components/index";
import Styles from "../styles";
import { FormFull } from "form-full";
import { hooks, masks } from "../../../utils";

import { fonts, Spacing, SVG } from "../../../config";
import { CircularProgress, Grid } from "@material-ui/core";
import { customModal } from "../../../components/modals/utils";
import { Visibility } from "@material-ui/icons";
import { api } from "../../../services";

function ModalImage({ item }) {
  const texts = ptBr.login;
  const { call, loading } = hooks.useRequest();
  const [image, setImage] = useState(null);

  useEffect(() => {
    call(null, api.getImageDownload(item.imageId), (response) => {
      if (response.ok) {
        setImage(response.data);
      }
    });
  }, [item]);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxHeight: "80vh",
            maxWidth: "80vw",
            height: "493px",
            width: "400px",
          }}
        >
          {" "}
          <CircularProgress color="secondary" />
        </div>
      ) : image ? (
        <div
          style={{
            marginInline: "20px",
            borderRadius: "5px",
            maxHeight: "80vh",
            maxWidth: "80vw",
            height: "493px",
            width: "400px",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundImage: `url(${image})`,
          }}
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxHeight: "80vh",
            maxWidth: "80vw",
            height: "493px",
            width: "400px",
            backgroundColor: "#ffffff1A",
          }}
        >
          {" "}
          <CustomText
            textColor="white"
            fontSize={2}
            fontFamily={fonts.semibold}
          >
            Sem Imagem
          </CustomText>{" "}
        </div>
      )}
    </>
  );
}

export default ModalImage;
