import React, { useState } from "react";
import { Add, ExpandMore } from "@material-ui/icons";
import {
  ButtonMenu,
  ContentFeedback,
  CustomText,
  Pagination,
  Table
} from "../../components";
import { SVG, fonts } from "../../config";
import Styles from "./styles";
import ModalRegistry from "./components/ModalRegistry";
import { HiPencil } from "react-icons/hi2";
import { ImKey } from "react-icons/im";
import { BsPersonFillSlash } from "react-icons/bs";
import { customModal } from "../../components/modals/utils";
import Filters from "./components/Filters";

export default function CleaningAndMaintenance() {
  // Estado para a página atual
  const [currentPage, setCurrentPage] = useState(1);
  // Estado para armazenar as informações do gestor selecionado
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const keyIconStyle = {
    transform: "scaleX(-1)" // Flip horizontal
  };

  const buttons = [
    {
      label: "Editar Dados do Funcionário",
      icon: HiPencil,
      onClick: () => openEditModal(selectedEmployee) // Abrir modal de edição com informações do gestor selecionado
    },
    {
      label: "Reenviar Chave de Acesso",
      icon: ImKey,
      style: keyIconStyle,
      onClick: () => {} // Função para reenviar chave de acesso ao gestor selecionado,
    },
    {
      label: "Revogar Acesso",
      icon: BsPersonFillSlash,
      onClick: () => {}
    }
  ];

  const mock = [
    {
      id: 1,
      name: "João da Silva",
      email: "joao.silva@example.com",
      team: "Limpeza"
    },
    {
      id: 2,
      name: "Maria Oliveira",
      email: "maria.oliveira@example.com",
      team: "Manutenção"
    },
    {
      id: 3,
      name: "Pedro Souza",
      email: "pedro.souza@example.com",
      team: "Limpeza"
    },
    {
      id: 4,
      name: "Ana Santos",
      email: "ana.santos@example.com",
      team: "Manutenção"
    }
    // Adicione mais objetos mock conforme necessário
  ];

  // Função para abrir o modal de edição com as informações do gestor selecionado
  const openEditModal = (employee) => {
    // Verifica se um gestor foi selecionado antes de abrir o modal
    if (employee) {
      customModal.setInfos(
        "Editar Dados do Funcionário",
        [],
        null,
        null,
        <ModalRegistry employee={employee} />, // Passa as informações do gestor selecionado para o modal de edição
        false,
        true,
        <SVG.File />
      );
    }
  };

  const selectEmployeeForEdit = (employee) => {
    setSelectedEmployee(employee);
  };

  const openModal = () => {
    customModal.setInfos(
      "Cadastrar Funcionário de Limpeza/Manutenção",
      [
        "Ao continuar e confirmar o cadastro deste funcionário, estaremos enviando um e-mail contendo a chave de acesso para todos os apartamentos."
      ],
      null,
      null,
      <ModalRegistry />,
      false,
      true,
      <SVG.File />
    );
  };

  return (
    <>
      <Styles.ContainerSecondary>
        <Styles.RegitryContainer>
          <CustomText fontSize={2} textColor="#FFFFFF" fontFamily={fonts.bold}>
            Cadastrar novo Funcionário de Limpeza/Manutenção
          </CustomText>
          <CustomText
            style={{ paddingRight: "8px" }}
            fontSize={1.75}
            textColor="#FFFFFF"
            fontFamily={fonts.regular}
          >
            Alguns dados básicos serão solicitados para o cadastro de um novo
            funcionário.
          </CustomText>
        </Styles.RegitryContainer>
        <Styles.RegitryButton
          startIcon={<Add />}
          fullWidth={false}
          onClick={openModal}
        >
          Cadastrar
        </Styles.RegitryButton>
      </Styles.ContainerSecondary>

      <Styles.ContainerFilter>
        <CustomText
          style={{ paddingBottom: "8px" }}
          fontSize={2}
          textColor="#4D585A"
          fontFamily={fonts.bold}
        >
          Filtrar Funcionários de Limpeza/Manutenção Cadastrados
        </CustomText>
        <Filters onSubmit={() => {}} loading={false} />
      </Styles.ContainerFilter>

      <Styles.Container noLine>
        <CustomText
          style={{ padding: "16px 20px" }}
          fontSize={2}
          textColor="#4D585A"
          fontFamily={fonts.bold}
        >
          Lista de Funcionários de Limpeza/Manutenção Cadastrados
        </CustomText>
        <ContentFeedback data={mock} loading={false}>
          <Table
            lastAlign={false}
            data={mock}
            renderItemColumns={(item) => [
              item.name,
              item.email,
              item.team,
              null,
              null,
              <ButtonMenu
                endIcon={<ExpandMore />}
                buttons={buttons}
                onClick={() => {
            
                  selectEmployeeForEdit(item);
                }}
              >
                Opções
              </ButtonMenu>
            ]}
            headers={{
              table: [
                "NOME COMPLETO",
                "EMAIL",
                "EQUIPE PERTENCENTE",
                "",
                "",
                "AÇÃO"
              ],
              keys: []
            }}
          />
          <Pagination
            style={{ padding: "16px 20px", paddingTop: 0 }}
            page={currentPage}
            totalPages={2}
            setPage={(number) => setCurrentPage(number)}
          />
        </ContentFeedback>
      </Styles.Container>
    </>
  );
}
