import styled from "styled-components";
import { ButtonContained, ButtonText, FontStyles } from "../../../components";
import { Spacing } from "../../../config";

const Container = styled.div(({ theme, noLine }) => {
  return {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    padding: "16px 20px",
    paddingInline: noLine && 0,
    borderRadius: "5px",
    width: "100%",
    marginBottom: theme.spacing(1.5),
    height: "fit-content"
  };
});

const ContainerColumn = styled.div(
  ({ theme, marginLeft, backgroundColor, border }) => {
    const { breakpoints } = theme;
    return {
      display: "flex",
      flexDirection: "column",
      paddingRight: 10,
      borderRadius: "5px",
      border: border,
      backgroundColor: backgroundColor,
      marginLeft: marginLeft ? marginLeft : 0,
      marginBottom: theme.spacing(1.5),
      flex: 1,
      height: "calc(100vh - 260px)",

      [breakpoints.down("xl")]: {
        height: "calc(100vh - 220px)",
        paddingRight: 6
      }
    };
  }
);

const ListColumn = styled.div(({ theme }) => {
  return {
    display: "flex",
    width: "100%",
    overflowX: "auto",
    justifyContent: "justify-between",

    //congif scroll
    "&::-webkit-scrollbar": {
      height: "16px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#4D585A",
      borderRadius: "7px",
      border: "4px solid #E0E0E0"
    },
    "&::-webkit-scrollbar-track": {
      background: "#E0E0E0",
      borderRadius: "5px"
    }
  };
});

const ListCard = styled.div(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "16px",
    paddingRight: "10px",
    borderRadius: "5px",
    overflowY: "auto",
    //congif scroll
    "&::-webkit-scrollbar": {
      width: "16px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#4D585A",
      borderRadius: "7px",
      border: "4px solid #E0E0E0"
    },
    "&::-webkit-scrollbar-track": {
      background: "#E0E0E0",
      borderRadius: "5px",
      marginBottom: "16px"
    },

    [breakpoints.down("xl")]: {
      paddingRight: "6px"
    }
  };
});

const ColumnHeader = styled.div(({ theme }) => {
  return {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "16px"
  };
});

const ColumnTitle = styled.div(({ theme, titleColor }) => {
  return {
    ...FontStyles.semibold14,
    display: "flex",
    color: titleColor ? titleColor : "#ffffff",
    fill: titleColor ? titleColor : "#ffffff",
    whiteSpace: "nowrap"
  };
});

// Estilo para os cards dentro da coluna
const Card = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 5px;
`;

const Separator = styled.div`
  height: 5px;
  background-color: #f5f5f5;
`;

const CardContainerHeader = styled.div(({ theme }) => {
  return {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px"
  };
});

const CardContainerContent = styled.div(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    padding: "16px"
  };
});

const CardContentItem = styled.div(({ theme }) => {
  const { breakpoints } = theme;

  return {
    display: "flex",
    alignItems: "center",

    [breakpoints.down("xl")]: {
      flexDirection: "column",
      alignItems: "flex-start"
    }
  };
});

const CardTitle = styled.div(({ theme }) => {
  return {
    ...FontStyles.bold16,
    color: theme.palette.text.primary,
    whiteSpace: "nowrap"
  };
});

const CardKey = styled.div(({ theme }) => {
  return {
    ...FontStyles.medium14,
    color: theme.palette.text.primary
  };
});

const CardValue = styled.div(({ theme }) => {
  const { breakpoints } = theme;

  return {
    ...FontStyles.regular14,
    color: theme.palette.text.primary,
    marginLeft: "10px",

    [breakpoints.down("xl")]: {
      marginLeft: 0,
      marginBottom: "6px"
    }
  };
});

const Styles = {
  Container,
  ContainerColumn,
  ColumnHeader,
  ColumnTitle,
  ListColumn,
  ListCard,
  Card,
  CardContainerHeader,
  CardContainerContent,
  CardContentItem,
  CardTitle,
  Separator,
  CardKey,
  CardValue
};

export default Styles;
