import React, { useContext } from "react";
import {
  ButtonContained,
  ButtonOutlined,
  CustomText,
  Input,
  InputAutocomplete,
  InputDate,
  InputTags,
  Table,
} from "../../components";
import { FormFull } from "form-full";
import { fonts, Theme } from "../../config";
import { StorageContext } from "../../contexts/StorageContext";
import ChartBox from "../../components/charts/chartBox";
import { models } from "../../utils";
import Styles from "./styles";

function Home() {
  return <></>;
}

export default Home;
