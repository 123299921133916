import React, { useState } from "react";
import Styles from "./styles/Styles";
import { fonts, SVG } from "../../config";
import {
    Add,
    ExpandMore,
    Lock,
    Public,
    Visibility,
} from "@material-ui/icons";
import Filters from "./components/Filters";
import { masks } from "../../utils";
import { customModal } from "../../components/modals/utils";
import ModalRegistry from "./components/ModalRegistry";
import ModalView from "./components/ModalView";
import { HiPencil } from "react-icons/hi2";
import ResendAccessModal from "./components/ResendAccessModal";
import { FaKey } from "react-icons/fa6";
import {
    ButtonMenu,
    ContentFeedback,
    CustomText,
    Pagination,
    Table,
} from "../../components";
import { BsPersonFillSlash } from "react-icons/bs";

export default function RegisterServiceProvider() {
    const [page, setPage] = React.useState<number>(1);
    const [totalPages, setTotalPages] = useState(0);

    const mockData = [
        {
            id: 1,
            companyName: "RTJ Serviços de Limpeza S.A.",
            cnpj: "03419015000972",
            visibilityLevel: "Global",
            emailCompany: "rtj@gestao.com.br",
            phone: "48 98904-0322",
            name: "Leandro Bittencourt",
            cpf: "002.498.509-23",
            date: "15/04/2024",
            Owner: "Proprietário",
        },
        {
            id: 2,
            companyName: "SC Manutenções LTDA.",
            cnpj: "94318002000102",
            visibilityLevel: "Privado",
            emailCompany: "governanca.scm@contato.br",
            phone: "48 98904-0322",
            name: "Leandro Bittencourt",
            cpf: "002.498.509-23",
            date: "15/04/2024",
            Owner: "Proprietário",
        },
        {
            id: 3,
            companyName: "RTJ Serviços de Limpeza S.A.",
            cnpj: "03419015000972",
            visibilityLevel: "Global",
            emailCompany: "rtj@gestao.com.br",
            phone: "48 98904-0322",
            name: "Leandro Bittencourt",
            cpf: "002.498.509-23",
            date: "15/04/2024",
            Owner: "Proprietário",
        },
        {
            id: 4,
            companyName: "SC Manutenções LTDA.",
            cnpj: "94318002000102",
            visibilityLevel: "Privado",
            emailCompany: "governanca.scm@contato.br",
            phone: "48 98904-0322",
            name: "Leandro Bittencourt",
            cpf: "002.498.509-23",
            date: "15/04/2024",
            Owner: "Proprietário",
        },
        {
            id: 5,
            companyName: "SC Manutenções LTDA.",
            cnpj: "94318002000102",
            visibilityLevel: "Privado",
            emailCompany: "governanca.scm@contato.br",
            phone: "48 98904-0322",
            name: "Leandro Bittencourt",
            cpf: "002.498.509-23",
            date: "15/04/2024",
            Owner: "Proprietário",
        },
    ];

    const openRegisterModal = () => {
        customModal.setInfos(
            "Cadastrar Prestadora de Serviço",
            [
                "Ao continuar e confirmar o cadastro desta empresa prestadora de serviço, enviaremos um e-mail contendo a chave de acesso o e-mail do responsável informado:",
            ],
            null,
            null,
            <ModalRegistry />,
            null,
            null,
            <SVG.Maintenance />
        );
    };

    const openModalView = (item: any) => {
        customModal.setInfos(
            "Visualizar Dados da Prestadora de Serviço",
            [],
            {
                onClick: () => {
                    customModal.close();
                },
                label: "Voltar",
            },
            null,
            <ModalView item={item} />,
            null,
            null,
            <Visibility />
        );
    };

    const openEditModal = (data) => {
        customModal.setInfos(
            "Editar Dados da Prestadora de Serviço",
            [],
            null,
            null,
            <ModalRegistry data={data} onSubmit={data} />,
            null,
            null,
            <HiPencil />
        );
    };

    const ResendModal = (item: any) => {
        customModal.setInfos(
            "Reenviar Chave de Acesso",
            ["Continuar e reenviar a chave de acesso para esta prestadora?"],
            {
                onClick: () => {
                    customModal.close();
                },
                label: "Sim, Reenviar",
            },
            {
                onClick: () => {
                    customModal.close();
                },
                label: "Voltar",
            },
            <ResendAccessModal item={item} />,
            null,
            null,
            <FaKey />
        );
    };

    const RevokeModalAccess = (id: string) => {
        customModal.setInfos(
            "Revogar Acesso",
            [
                "Ao revogar o acesso deste imóvel, a empresa prestadora de serviço terá sua chave de acesso atual desativada. Continuar e revogar acesso?",
            ],
            {
                label: "Sim, Revogar",
                onClick: () => {
                    customModal.close();
                },
            },
            {
                label: "Voltar",
                onClick: () => {
                    customModal.close();
                },
            },
            null,
            null,
            null,
            <BsPersonFillSlash />
        );
    };

    const getButtons = (item: any): any[] => {
        return [
            {
                label: "Visualizar Dados da Prestadora de Serviço",
                icon: Visibility,
                onClick: () => openModalView(item),
            },
            {
                label: "Editar Dados da Prestadora de Serviço",
                icon: HiPencil,
                onClick: () => openEditModal(item),
            },
            {
                label: "Reenviar Chave de Acesso",
                icon: FaKey,
                onClick: () => ResendModal(item),
            },
            {
                label: "Revogar Acesso",
                icon: BsPersonFillSlash,
                onClick: () => RevokeModalAccess(item.id),
            },
        ];
    };

    function StatusVisibility(item: string) {
        switch (item) {
            case "Privado":
                return (
                    <>
                        <Styles.Visibility $visibility={item}>
                            <Lock fontSize="small" />
                            {item}
                        </Styles.Visibility>
                    </>
                );

            case "Global":
                return (
                    <>
                        <Styles.Visibility $visibility={item}>
                            <Public fontSize="small" />
                            {item}
                        </Styles.Visibility>
                    </>
                );
            default:
                return null;
        }
    }

    return (
        <>
            <Styles.PageNameContainer>
                <Styles.PageNameContent>
                    <CustomText fontSize={2} textColor="#FFFFFF" fontFamily={fonts.bold}>
                        Cadastrar Prestadora de Serviço
                    </CustomText>
                    <CustomText
                        style={{ paddingRight: "8px" }}
                        fontSize={1.75}
                        textColor="#FFFFFF"
                        fontFamily={fonts.regular}
                    >
                        Alguns dados básicos serão solicitados para o cadastro de uma nova
                        empresa prestadora de serviço.
                    </CustomText>
                </Styles.PageNameContent>
                <Styles.RegitryButton
                    startIcon={<Add />}
                    fullWidth={false}
                    onClick={() => openRegisterModal()}
                >
                    Cadastrar
                </Styles.RegitryButton>
            </Styles.PageNameContainer>

            <Styles.ContainerFilter>
                <CustomText
                    style={{ paddingBottom: "8px" }}
                    fontSize={2}
                    textColor="#4D585A"
                    fontFamily={fonts.bold}
                >
                    Filtrar Prestadoras de Serviço Cadastradas
                </CustomText>
                <Filters onSubmit={(data) => console.log(data)} loading={false} />
            </Styles.ContainerFilter>

            <Styles.Container>
                <CustomText
                    style={{ paddingBottom: "8px" }}
                    fontSize={2}
                    textColor="#4D585A"
                    fontFamily={fonts.bold}
                >
                    Filtrar Prestadoras de Serviço Cadastradas
                </CustomText>
                <ContentFeedback data={mockData} loading={false}>
                    <Table
                        lastAlign={false}
                        data={mockData}
                        renderItemColumns={(item) => [
                            item.companyName,
                            masks.CNPJ(item.cnpj),
                            item.emailCompany,
                            StatusVisibility(item.visibilityLevel),
                            null,
                            null,
                            <ButtonMenu endIcon={<ExpandMore />} buttons={getButtons(item)}>
                                Opções
                            </ButtonMenu>,
                        ]}
                        headers={{
                            table: [
                                "NOME DA EMPRESA",
                                "E-MAIL REPRESENTANTE",
                                "CNPJ",
                                "NÍVEL DE VISIBILIDADE",
                                "",
                                "",
                                "AÇÃO",
                            ],
                            keys: [],
                        }}
                    />
                    <Pagination
                        style={{ padding: "16px 20px", paddingTop: 0 }}
                        page={page}
                        totalPages={totalPages}
                        setPage={(number) => setPage(number)}
                    />
                </ContentFeedback>
            </Styles.Container>
        </>
    );
}
