import styled from "styled-components";
import { ButtonContained, ButtonText, FontStyles } from "../../../components";

const Container = styled.div(({ theme, noLine }) => {
    return {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flexDirection: "column",
        backgroundColor: theme.palette.background.paper,
        padding: "16px 20px",
        paddingInline: noLine && 0,
        borderRadius: "5px",

        width: "100%",
        marginBottom: theme.spacing(1.5),
        height: "fit-content",
    };
});

const ContainerFilter = styled.div(({ theme, noLine }) => {
    return {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flexDirection: "column",
        backgroundColor: theme.palette.background.paper,
        padding: "16px 20px",
        paddingInline: noLine && 0,
        borderRadius: "5px",

        width: "100%",
        marginBottom: theme.spacing(1.5),
        height: "fit-content",
    };
});

const PageNameContent = styled.div(({ theme }) => {
    return {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(0.75),
    };
});

const PageNameContainer = styled.div(({ theme }) => {
    return {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "row",
        backgroundColor: theme.palette.action.selected,
        padding: "16px 20px",
        borderRadius: "5px",

        width: "100%",
        marginBottom: theme.spacing(1.5),
        height: "fit-content",

        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            alignItems: "flex-end",
            gap: "8px",
        },
    };
});

const RegitryButton = styled(ButtonContained)(({ theme }) => {
    const { palette: colors, spacing } = theme;
    return {
        "&&.MuiButton-root": {
            backgroundColor: "white",
            color: colors.text.primary,
            marginTop: 0,
            ...FontStyles.medium14,

            "&:hover": {
                backgroundColor: colors.primary.contrastText,
            },

            "& .MuiButton-label": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            },
        },
    };
});

const ButtonContainer = styled.div(({ theme }) => {
    return {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flexDirection: "row",
        gap: theme.spacing(1),
    };
});

const Submit = styled(ButtonContained)(({ theme }) => {
    return {
        "&&.MuiButton-root": {
            backgroundColor: theme.palette.action.selected,
            ...FontStyles.semibold12,
            paddingInline: theme.spacing(2.5),
            paddingBlock: theme.spacing(1.9),
        },
    };
});
const Clear = styled(ButtonText)(({ theme }) => {
    return {
        "&&.MuiButton-root": {
            color: theme.palette.action.selected,
            ...FontStyles.semibold12,
            paddingInline: theme.spacing(2.5),
            paddingBlock: theme.spacing(1.9),
        },
    };
});

const Visibility = styled.div(({ theme, $visibility }) => {
    const { palette: colors, spacing } = theme;
    return {
        backgroundColor:
            $visibility === "Privado" ? colors.background.paper : colors.primary.main,
        color:
            $visibility === "Privado" ? colors.primary.main : colors.background.paper,
        padding: spacing(1),
        border: "solid 3px",
        borderRadius: spacing(3),
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: spacing(0.5),
        flex: 0.6,
        ...FontStyles.semibold12,
    };
});

const CancelButton = styled(ButtonText)(({ theme }) => {
    const { palette: colors, spacing } = theme;
    return {
        "&&.MuiButton-root": {
            marginTop: 0,
            ...FontStyles.medium14,
            color: "white",
            paddingInline: spacing(2),
            "& .MuiButton-label": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            },
        },
    };
});

const InfoName = styled.p(({ theme }) => {
    const { spacing } = theme;
    return {
        ...FontStyles.semibold14,
        textTransform: "uppercase",
        padding: 0,
        margin: 0,
        color: "white",
        transition: ".2s",
        pointerEvents: "none",
        alignItems: "center",
        display: "flex",
        overflow: "hidden",
        paddingTop: spacing(2.5),
    };
});
const InfoValueData = styled.p(({ theme }) => {
    const { spacing } = theme;
    return {
        ...FontStyles.medium14,
        paddingBlock: spacing(1.25),
        color: "white",
        width: "100%",
        borderBottom: "1px solid #fff",
        margin: 0,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    };
});

const customLine = styled.div(({ theme }) => {
    const { spacing } = theme;
    return {
        width: "100%",
        height: "0.2rem",
        backgroundColor: "#313437",
        border: "none",
        marginTop: spacing(4),
        marginBottom: spacing(3),
    };
});

const Styles = {
    Container,
    ContainerFilter,
    PageNameContent,
    PageNameContainer,
    RegitryButton,
    ButtonContainer,
    Clear,
    Submit,
    Visibility,
    CancelButton,
    InfoName,
    InfoValueData,
    customLine,
};

export default Styles;
