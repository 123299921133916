const colors = {
  // TODO/HINT REMOVER Cores projeto anterior
  whiteBackground: "#FFFFFF",
  grayBackground: "#8D9B9C",

  disabled: "#D2D2D2",

  inputGray: "#E9E9E9",
  black: "#24272A",
  gray: "#4D585A",
  lightGray: "#00000054",

};

export default colors;
