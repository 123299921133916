import React, { useState } from "react";
import {
  CustomText,
  Input,
  InputRadios,
  InputTags,
} from "../../../components/index";
import { Grid } from "@material-ui/core";
import { validations } from "../../../utils";
import { fonts } from "../../../config";
import Styles from "../styles";
import { FormFull, FormFullData } from "form-full";
import { customModal } from "../../../components/modals/utils";
import { OwnersProps } from "../RegisterOwners";

export interface Studio {
  label: string;
  value: string;
}

export interface DataRegisterProps {
  id: string;
  name: string;
  email: string;
  cpf: string;
  studios: Studio[];
}

export interface DataEditProps {
  id: string;
  name: string;
  studios: Studio[];
}

function ModalRegistry({
  data,
  view,
  onSubmit,
  placeList,
}: {
  data?: OwnersProps;
  view?: boolean;
  onSubmit?: (newOwner: DataRegisterProps) => void;
  placeList?: any[];
}) {
  const [formRef, setFormRef] = useState<any>(null);

  const selectedStudios = data?.places || [];

  const filteredPlaceList = placeList?.filter((place) =>
    selectedStudios.some((studio) => studio.id === place.value)
  );

  const handleSubmit = (
    newOwner: FormFullData<{
      name: string;
      email: string;
      cpf: string;
      studios: string[];
    }>
  ) => {
    if (onSubmit)
      onSubmit({
        id: data ? data.id : "",
        cpf: newOwner.cpf,
        email: newOwner.email,
        name: newOwner.name,
        studios: newOwner.studios,
      });
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          minWidth: "clamp(300px, 20vw, 650px)",
          paddingInline: "25px",
        }}
      >
        <FormFull formRef={setFormRef} onSubmit={handleSubmit}>
          <CustomText
            style={{ marginBottom: "20px" }}
            fontFamily={fonts.bold}
            fontSize={14 / 8}
            textColor="#B2B2B2"
          >
            DADOS BÁSICOS DO PROPRIETÁRIO
          </CustomText>
          <Grid alignItems="flex-end" container spacing={1}>
            <Grid item xs={12} md={6} lg={6}>
              <Input
                readOnly={view}
                required={"*Campo Obrigatório"}
                name="name"
                defaultValue={data?.name || ""}
                validation={validations.isValidFullname}
                label="Nome Completo"
                white
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Input
                readOnly={Boolean(data)}
                required={!Boolean(data) ? "*Campo Obrigatório" : undefined}
                validation={validations.validateCPF}
                defaultValue={data?.cpf || ""}
                name="cpf"
                label="CPF"
                white
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Input
                readOnly={Boolean(data)}
                required={!Boolean(data) ? "*Campo Obrigatório" : undefined}
                validation={validations.isEmailValid}
                defaultValue={data?.email || ""}
                name="email"
                label="E-Mail"
                white
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <InputTags
                white
                readOnly={view}
                required={"*Campo Obrigatório"}
                defaultValue={data?.places ? filteredPlaceList : null}
                name="studios"
                label="VINCULAR A STUDIOS"
                options={placeList ? placeList : []}
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "12px",
              paddingTop: "12px",
              width: "100%",
            }}
          >
            {!view && (
              <Styles.CancelButton
                fullWidth={false}
                onClick={() => {
                  customModal.close();
                }}
              >
                Cancelar
              </Styles.CancelButton>
            )}
            <Styles.RegitryButton
              fullWidth={false}
              onClick={async () => {
                if (view) {
                  customModal.close();
                } else {
                  const submitInfo = await formRef?.testErrorsAndReturnData(
                    true
                  );

                  if (!submitInfo?.hasError) {
                    formRef?.submit();
                  }
                }
              }}
            >
              {view
                ? "Voltar"
                : data
                ? "Confirmar Edição"
                : "Confirmar Cadastro"}
            </Styles.RegitryButton>
          </div>
        </FormFull>
      </div>
    </>
  );
}

export default ModalRegistry;
