import React from "react";
import ptBr from "../../../config/texts/pt-br";
import { Grid } from "@material-ui/core";
import Styles from "../styles/Styles";
import { masks } from "../../../utils";
import { fonts } from "../../../config";
import { CustomText } from "../../../components";

function ResendAccessModal({ item }) {
    const texts = ptBr.login;

    return (
        <>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    minWidth: "clamp(250px, 26vw, 500px)",
                    paddingInline: "25px",
                    marginBottom: "20px",
                }}
            >
                <CustomText
                    style={{ marginBottom: "20px" }}
                    fontFamily={fonts.bold}
                    fontSize={14 / 8}
                    textColor="#B2B2B2"
                >
                    DADOS DA PRESTADORA DE SERVIÇO:
                </CustomText>
                <Grid alignItems="flex-end" container spacing={1}>
                    <Grid item xs={12} md={6} lg={6}>
                        <Styles.InfoName>NOME DA EMPRESA</Styles.InfoName>
                        <Styles.InfoValueData>{item?.companyName}</Styles.InfoValueData>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Styles.InfoName>CNPJ</Styles.InfoName>
                        <Styles.InfoValueData>
                            {masks.CNPJ(item?.cnpj)}
                        </Styles.InfoValueData>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Styles.InfoName>NOME DO RESPONSÁVEL</Styles.InfoName>
                        <Styles.InfoValueData>{item?.cnpj}</Styles.InfoValueData>
                    </Grid>{" "}
                    <Grid item xs={12} md={6} lg={6}>
                        <Styles.InfoName>TELEFONE</Styles.InfoName>
                        <Styles.InfoValueData>{item?.phone}</Styles.InfoValueData>
                    </Grid>{" "}
                    <Grid item xs={12} md={6} lg={12}>
                        <Styles.InfoName>E-MAIL RESPONSÁVEL</Styles.InfoName>
                        <Styles.InfoValueData>{item?.emailCompany}</Styles.InfoValueData>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

export default ResendAccessModal;
