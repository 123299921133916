import React, { useEffect, useState } from "react";
import Styles from "./../styles";
import { BsThreeDotsVertical } from "react-icons/bs";
import ButtonMenu from "./ButtonMenu";
import { fonts, SVG } from "../../../config";
import MaintenanceTag from "./MaintenanceTag";
import { api } from "../../../services";
import { Modal } from "../../../components/modals";
import { customModal } from "../../../components/modals/utils";
import { Visibility } from "@material-ui/icons";
import { CustomText } from "../../../components";

interface CardData {
  key: string;
  value: string;
}

interface PropertyCardProps {
  data: {
    title: string;
    info: CardData[];
    status: string;
    id?: string;
    maintenance?: boolean;
    futureInfo: CardData[];
  };
  onStatusChange: () => void;
}

const PropertyCard: React.FC<PropertyCardProps> = ({
  data,
  onStatusChange,
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setMenuOpen(false);
  };

  const openModal = () => {
    customModal.setInfos(
      "Remover Manutenção",
      ["Tem certeza que deseja remover o aviso de manutenção deste imóvel?"],
      {
        onClick: () => {
          api.updatePlaceOffMaintenance(data.id).then(() => {
            onStatusChange();
          });
          customModal.close();
        },
        label: "Sim, Remover Manutenção",
      },
      {
        onClick: () => {
          customModal.close();
        },
        label: "Cancelar",
      },
      null,
      false,
      true,
      <SVG.Maintenance />
    );
  };

  const menuButtons = [
    {
      label: "Mover para Disponível",
      status: "DISPONIVEL",
      icon: SVG.Exit,
      onClick: () => {
        api.updatePlaceAvailability(data.id).then(() => {
          onStatusChange();
        });
      },
    },
    {
      label: "Mover para Vago Sujo",
      status: "VAGO_SUJO",
      icon: SVG.VagoSujo,
      onClick: () => {
        api.updatePlaceDirty(data.id).then(() => {
          onStatusChange();
        });
      },
    },
    {
      label: "Mover para Vago Limpo",
      status: "VAGO_LIMPO",
      icon: SVG.VagoLimpo,
      onClick: () => {
        // api.updatePlaceClean(data.id).then(() => {
        //   onStatusChange();
        // });
      },
    },
    {
      label: "Mover para Ocupado",
      status: "OCUPADO",
      icon: SVG.Ocupado,
      onClick: () => {
        api.updatePlaceBusy(data.id).then(() => {
          onStatusChange();
        });
      },
    },
    {
      label: "Mover para Indisponível",
      status: "INDISPONIVEL",
      icon: SVG.Indisponivel,
      onClick: () => {
        api.updatePlaceUnavailable(data.id).then(() => {
          onStatusChange();
        });
      },
    },
    {
      label: "Indicar Manutenção",
      status: "MANUTENCAO",
      icon: SVG.Maintenance,
      onClick: () => {
        api.updatePlaceOnMaintenance(data.id).then(() => {
          onStatusChange();
        });
      },
    },
  ];

  const filteredMenuButtons = menuButtons.filter((button) => {
    if (data.maintenance) {
      return button.status !== "MANUTENCAO";
    } else {
      return button.status !== data.status;
    }
  });

  return (
    <>
      <Styles.Card>
        <Styles.CardContainerHeader>
          <Styles.CardTitle>{data.title}</Styles.CardTitle>
          {/* Ícone de Dots com menu */}
          <div style={{ cursor: "pointer" }}>
            <BsThreeDotsVertical size={20} onClick={handleMenuClick} />
          </div>
          {/* Componente do menu */}
          <ButtonMenu
            anchorEl={menuAnchorEl}
            open={menuOpen}
            handleClose={handleMenuClose}
            buttons={filteredMenuButtons}
          />
        </Styles.CardContainerHeader>
        <Styles.Separator />
        <Styles.CardContainerContent>
          {data.info.map((item, index) => (
            <Styles.CardContentItem key={index}>
              <Styles.CardKey>{item.key}:</Styles.CardKey>
              <Styles.CardValue>{item.value}</Styles.CardValue>
            </Styles.CardContentItem>
          ))}
          {data.futureInfo && data.status !== "OCUPADO" && (
            <>
              <CustomText fontFamily={fonts.semibold} fontSize={1.6}>
                Proxima reserva:
              </CustomText>
              {data.futureInfo.map((item, index) => (
                <Styles.CardContentItem key={index}>
                  <Styles.CardKey>{item.key}:</Styles.CardKey>
                  <Styles.CardValue>{item.value}</Styles.CardValue>
                </Styles.CardContentItem>
              ))}
            </>
          )}
          {data.maintenance && (
            <div onClick={openModal}>
              <MaintenanceTag />
            </div>
          )}
        </Styles.CardContainerContent>
      </Styles.Card>
    </>
  );
};

export default PropertyCard;
