import React from "react";
import { Menu, MenuItem, Fade, ListItemIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { Spacing } from "../../../config";

const StyledButtonList = styled(MenuItem)(({ theme }) => {
  const { spacing } = theme;
  return {
    "&&.MuiMenuItem-root": {
      display: "flex",
      height: "fit-content",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: spacing(0.9),
      marginLeft: Spacing(1),
      flexShrink: 0,
      overflow: "hidden",

      color: "#4D585A",
      background: "white",
      "&.MuiListItem-button:hover": {
        background: "white"
      },
      "&.Mui-focusVisible": {
        background: "white"
      },
      "&.Mui-selected": {
        background: "white"
      },
      "& .MuiPaper-root": {
        background: "white",
        padding: 0
      }
    }
  };
});

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    backgroundColor: "#fff",
    border: "2px solid #E8E8E8",
    boxShadow: "0px 0px 8px #00000033",
    marginTop: theme.spacing(0.6)
  }
}));

function ButtonMenu({ anchorEl, open, handleClose, buttons }) {
  const classes = useStyles();

  return (
    <Menu
      classes={{
        paper: classes.menuPaper
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      TransitionComponent={Fade}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      elevation={0}
      getContentAnchorEl={null}
    >
      {buttons?.length > 0 ? (
        buttons?.map((item, index) => (
          <StyledButtonList
            key={index}
            onClick={() => {
              handleClose();
              item?.onClick();
            }}
          >
            {item?.label}
            {item?.icon && (
              <ListItemIcon
                style={{
                  minWidth: 0,
                  maxWidth: "20px",
                  marginLeft: Spacing(1),
                  color: "#4D585A"
                }}
              >
                <item.icon />
              </ListItemIcon>
            )}
          </StyledButtonList>
        ))
      ) : (
        <StyledButtonList
          style={{
            paddingInline: Spacing(1)
          }}
          onClick={handleClose}
        >
          Sem opções
        </StyledButtonList>
      )}
    </Menu>
  );
}

export default ButtonMenu;
