export default function objectToQueryString(
  path: string,
  obj: Record<string, any>
): string {
  const queryParams: any = [];

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];

      if (Array.isArray(value)) {
        value.forEach((item) => {
          queryParams.push(
            `${key}=${item}`
          );
        });
      } else {
        queryParams.push(
          `${key}=${value}`
        );
      }
    }
  }

  return queryParams.length > 0 ? path + "?" + queryParams.join("&") : path;
}
