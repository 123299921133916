import React, { useContext, useState } from "react";
import { Add, ExpandMore, GroupAdd, Visibility } from "@material-ui/icons";
import {
  ButtonMenu,
  ContentFeedback,
  CustomText,
  Pagination,
  Table,
} from "../../components";
import { SVG, fonts, Spacing } from "../../config";
import Styles from "./styles";
import ModalRegistry, {
  DataEditProps,
  DataRegisterProps,
} from "./components/ModalRegistry";
import { HiPencil } from "react-icons/hi2";
import { ImKey } from "react-icons/im";
import { BsPersonFillSlash } from "react-icons/bs";
import { customModal } from "../../components/modals/utils";
import Filters from "./components/Filters";
import { api } from "../../services";
import { hooks } from "../../utils";
import { CircularProgress } from "@material-ui/core";
import ModalRevoke from "./components/ModalRevoke";
import { StorageContext } from "../../contexts/StorageContext";

export interface OwnersProps {
  id: string;
  email: string;
  name: string;
  cpf: string;
  places: {
    externalId: string;
    id: string;
    name: string;
  }[];
}

const PAGE_SIZE = 8;

export default function RegisterOwners() {
  const { loading, call } = hooks.useRequest();
  const { userData } = useContext<any>(StorageContext);
  const [page, setPage] = React.useState<number>(0);
  const [filters, setFilters] = React.useState<any>(null);

  const [placeList, setPlaceList] = React.useState<any>([]);
  const [ownersList, setOwnersList] = useState<{
    content: OwnersProps[];
    totalPages: number;
  } | null>(null);
  const [totalPages, setTotalPages] = useState(0);

  const Mount = React.useCallback(() => {
    function hasAccess(userRoles: string[], pageRoles: string[]): boolean {
      if (!pageRoles || pageRoles?.length === 0) {
        return true;
      }

      return userRoles?.some((role) => pageRoles?.includes(role));
    }

    const isSuperAdmin = hasAccess(userData?.ROLES, ["ROLE_ADMIN_HABITAT"]);

    call(
      null,
      api.getPlaces({
        page: 0,
        size: 999999,
      }),
      async (response) => {
        if (response.ok) {
          const list = response?.data?.content.map((item) => {
            return { label: item.name, value: item.id, user: item.user };
          });

          setPlaceList(list);
        }
      }
    );
    filterOwners({}, 0);
  }, []);

  React.useEffect(() => {
    Mount();
  }, [Mount]);

  const registerOwner = async (data: DataRegisterProps) => {
    const { cpf, email, name } = data;
    const studiosIds = data.studios.map((studio) => {
      return studio.value;
    });
    call(
      null,
      api.postOwners({
        cpf,
        email,
        name,
        studiosIds: studiosIds ?? [],
      }),
      async (response) => {
        if (response.ok) {
          filterOwners(filters, page);
          customModal.close();
        }
      }
    );
  };

  const editOwner = async (data: DataEditProps) => {
    const { id, name } = data;
    const studiosIds = data.studios.map((studio) => {
      return studio.value;
    });
    call(
      null,
      api.putOwners({
        id,
        name,
        studiosIds: studiosIds ?? [],
      }),
      async (response) => {
        if (response.ok) {
          filterOwners(filters, page);
          customModal.close();
        }
      }
    );
  };

  const deleteOwner = async (id: string) => {
    customModal.close();

    call(null, api.deleteOwners({ id }), (response) => {
      if (response.ok) {
        filterOwners(filters, 0);
      }
    });
  };

  const filterOwners = (
    filterData?: {
      fullName?: string;
      email?: string;
      cpf?: string;
    },
    page?: number
  ) => {
    let filters = {
      page: page ? page : 0,
      size: PAGE_SIZE,
    };
    if (filterData?.fullName) {
      filters["fullName"] = filterData.fullName;
    }
    if (filterData?.email) {
      filters["email"] = filterData.email;
    }
    if (filterData?.cpf) {
      filters["cpf"] = filterData.cpf;
    }

    call(null, api.getOwners(filters), async (response) => {
      if (response.ok) {
        setOwnersList(response?.data);
        setPage(page ? page : 0);
        setFilters(filterData);
      }
    });
  };

  const getButtons = (item: any): any[] => {
    return [
      {
        label: "Visualizar Dados",
        icon: Visibility,
        onClick: () => openViewModal(item),
      },
      {
        label: "Editar Dados",
        icon: HiPencil,
        onClick: () => openEditModal(item),
      },

      {
        label: "Revogar Acesso",
        icon: BsPersonFillSlash,
        onClick: () => openRevokeModal(item),
      },
    ];
  };

  const openEditModal = (data) => {
    if (data) {
      customModal.setInfos(
        "Editar Dados do Proprietário",
        [],
        null,
        null,
        <ModalRegistry
          data={data}
          placeList={placeList?.filter((item) => {
            if (item.user?.role === "admin") {
              return item.user?.id === data?.id;
            } else {
              return true;
            }
          })}
          onSubmit={editOwner}
        />,
        false,
        true,
        <HiPencil />
      );
    }
  };

  const openViewModal = (data) => {
    if (data) {
      customModal.setInfos(
        "Visualizar Dados do Proprietário",
        [],
        null,
        null,
        <ModalRegistry data={data} view placeList={placeList} />,
        false,
        true,
        <Visibility />
      );
    }
  };

  const openRevokeModal = (data) => {
    if (data) {
      customModal.setInfos(
        "Revogar Acesso de Proprietário",
        [],
        null,
        null,
        <ModalRevoke
          data={data}
          onSubmit={deleteOwner}
          placeList={placeList}
        />,
        false,
        true,
        <BsPersonFillSlash />
      );
    }
  };

  const createOwner = () => {
    customModal.setInfos(
      "Cadastrar Proprietário",
      [],
      null,
      null,
      <ModalRegistry
        placeList={placeList?.filter((item) => {
          if (item.user?.role === "admin") {
            return item.user?.id === userData?.id;
          } else {
            return true;
          }
        })}
        onSubmit={(data) => {
          registerOwner(data);
        }}
      />,
      false,
      true,
      <GroupAdd />
    );
  };

  return (
    <>
      <Styles.ContainerSecondary>
        <Styles.RegitryContainer>
          <CustomText fontSize={2} textColor="#FFFFFF" fontFamily={fonts.bold}>
            Cadastrar novo Proprietário
          </CustomText>
          <CustomText
            style={{ paddingRight: "8px" }}
            fontSize={1.75}
            textColor="#FFFFFF"
            fontFamily={fonts.regular}
          >
            Alguns dados básicos serão solicitados para o cadastro de um novo
            proprietário.
          </CustomText>
        </Styles.RegitryContainer>
        <Styles.RegitryButton
          startIcon={<Add />}
          fullWidth={false}
          onClick={createOwner}
        >
          Cadastrar
        </Styles.RegitryButton>
      </Styles.ContainerSecondary>

      <Styles.ContainerFilter>
        <CustomText
          style={{ paddingBottom: "8px" }}
          fontSize={2}
          textColor="#4D585A"
          fontFamily={fonts.bold}
        >
          Filtrar Proprietários Cadastrados
        </CustomText>
        <Filters onSubmit={filterOwners} loading={false} />
      </Styles.ContainerFilter>

      <Styles.Container noLine>
        <CustomText
          style={{ padding: "16px 20px" }}
          fontSize={2}
          textColor="#4D585A"
          fontFamily={fonts.bold}
        >
          Lista de Proprietários Cadastrados
        </CustomText>
        {loading ? (
          <Styles.ContainerList>
            <CircularProgress />
          </Styles.ContainerList>
        ) : (
          <ContentFeedback data={ownersList?.content} loading={false}>
            <Table
              lastAlign={false}
              data={ownersList?.content ? ownersList?.content : []}
              renderItemColumns={(item) => [
                item.name,
                item.email,
                item.cpf,
                <ButtonMenu endIcon={<ExpandMore />} buttons={getButtons(item)}>
                  Opções
                </ButtonMenu>,
              ]}
              headers={{
                table: ["NOME COMPLETO", "EMAIL", "CPF", "AÇÃO"],
                keys: [],
              }}
            />
            <Pagination
              style={{ padding: "16px 20px", paddingTop: 0 }}
              page={page + 1}
              totalPages={ownersList?.totalPages}
              setPage={(number) => {
                filterOwners(filters, number - 1);
              }}
            />
          </ContentFeedback>
        )}
      </Styles.Container>
    </>
  );
}
