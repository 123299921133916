import React from "react";
import { CustomText } from "../../components";
import { SVG, fonts } from "../../config";
import Styles from "./styles";
import Column from "./components/Column";
import { api } from "../../services";
import { hooks } from "../../utils";

interface PlaceProps {
  id: string;
  name: string;
  externalId: string;
  currentStartDateReservation: string;
  currentEndDateReservation: string;
  currentReservationId: string;
  currentReservationStatus: string;
  status: string;
  maintenance: boolean;
}

interface DataCardsProps {
  title: string;
  info: Array<{
    key: string;
    value: string;
  }>;
}

export default function StudioManagement() {
  const { loading, call } = hooks.useRequest();

  const [places, setPlaces] = React.useState<PlaceProps[] | null>(null);
  const [isMounted, setMount] = React.useState<boolean>(false);

  const Mount = React.useCallback(() => {
    call(
      null,
      api.getPlaces({
        page: 0,
        size: 1000,
      }),
      async (response) => {
        if (response.ok) {
          setPlaces(response?.data.content);
        }
      }
    );
  }, []);

  const handleStatusChange = () => {
    Mount();
  };

  React.useEffect(() => {
    if (!isMounted) {
      Mount();
      setMount(true);
    }
  }, [Mount, isMounted]);

  const filteredPlaces = (status: string) => {
    if (!places) return [];
    return places.filter((place) => place.status === status);
  };

  const organizeDate = (date: string) => {
    if (date) {
      const dateArray = date.split("T");
      const days = dateArray[0].split("-");
      const hours = dateArray[1].split(":");
      return `${days[2]}/${days[1]}/${days[0]} - ${hours[0]}:${hours[1]}`;
    } else {
      return date;
    }
  };
  function getInfo(place): { key: string; value: string }[] {
    const info: { key: string; value: string }[] = [];
    if (place.currentStartDateReservation !== null) {
      info.push({
        key: "Check-in",
        value: organizeDate(place.currentStartDateReservation), // check-in sempre as 15:00
      });
    }
    if (place.currentEndDateReservation !== null) {
      info.push({
        key: "Check-out",
        value: organizeDate(place.currentEndDateReservation), // check-out sempre as 10:00
      });
    }

    return info;
  }

  function getFutureInfo(place): { key: string; value: string }[] {
    const info: { key: string; value: string }[] = [];
    if (place.nextStartDateReservation !== null) {
      info.push({
        key: "Check-in",
        value: organizeDate(place.nextStartDateReservation), // check-in sempre as 15:00
      });
    }
    if (place.nextEndDateReservation !== null) {
      info.push({
        key: "Check-out",
        value: organizeDate(place.nextEndDateReservation), // check-out sempre as 10:00
      });
    }

    return info;
  }

  function mapFilteredCards(filteredCards) {
    return filteredCards.map((place) => ({
      title: place.name,
      id: place.id,
      maintenance: place.maintenance,
      status: place.status,
      info: getInfo(place),
      futureInfo: getFutureInfo(place),
    }));
  }

  const infoColumns = [
    {
      title: "DISPONÍVEL",
      status: "DISPONIVEL",
      icon: SVG.Exit,
      color: "#4D585A",
    },
    {
      title: "OCUPADO",
      status: "OCUPADO",
      icon: SVG.Ocupado,
      color: "#24272A",
    },
    {
      title: "VAGO SUJO",
      status: "VAGO_SUJO",
      icon: SVG.VagoSujo,
      color: "#F6F6F6",
      border: "1px solid #E2E2E2",
      titleColor: "#24272A",
    },
    {
      title: "VAGO LIMPO",
      status: "VAGO_LIMPO",
      icon: SVG.VagoLimpo,
      color: "#686e74",
    },
    {
      title: "INDISPONÍVEL",
      status: "INDISPONIVEL",
      icon: SVG.Indisponivel,
      color: "#4D585A",
    },
  ];

  return (
    <div>
      {/* Aplicando o estilo Container */}
      <Styles.Container>
        <CustomText
          style={{ paddingBottom: "8px" }}
          fontSize={2}
          textColor="#4D585A"
          fontFamily={fonts.bold}
        >
          Status dos Imóveis
        </CustomText>

        <Styles.ListColumn>
          {infoColumns.map((column, index) => {
            const filteredCards = filteredPlaces(column.status);
            const cards = mapFilteredCards(filteredCards);
            return (
              <Column
                dataColumn={column}
                key={index}
                marginLeft={index === 0 ? 0 : 10}
                cards={cards}
                onStatusChange={handleStatusChange}
              />
            );
          })}
        </Styles.ListColumn>
      </Styles.Container>
    </div>
  );
}
