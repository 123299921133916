import React, { useContext, useState } from "react";
import {
  CustomText,
  Input,
  InputAutocomplete,
  InputRadios,
  InputTags,
} from "../../../components/index";
import { Grid } from "@material-ui/core";
import { hooks, masks, validations } from "../../../utils";
import { fonts } from "../../../config";
import Styles from "../styles/Styles";
import { FormFull, FormFullData } from "form-full";
import { customModal } from "../../../components/modals/utils";
import { ManagerProps } from "../managerRegistryPage";
import { api } from "../../../services";
import { StorageContext } from "../../../contexts/StorageContext";

export interface Owners {
  label: string;
  value: string;
  places: {
    label: string;
    value: string;
  }[];
}

interface ListStudios {
  label: string;
  value: string;
}

export interface DataRegisterProps {
  id: string;
  name: string;
  email: string;
  cpf: string;
  studios: ListStudios[];
  owners: Owners[];
}

export interface DataEditProps {
  id: string;
  name: string;
  cpf: string;
  studios: ListStudios[];
  owners: Owners[];
}

function ModalRegistry({
  data,
  ownersList,
  isManager,
  filterManager,
  viewOnly,
}: {
  data?: any;
  ownersList: any[];
  isManager: boolean;
  filterManager?: () => void;
  viewOnly?: boolean;
}) {
  const { loading, call } = hooks.useRequest();

  const [formRef, setFormRef] = React.useState<any>(null);
  const selectedOwners = isManager ? ownersList : data?.users || [];
  const selectedStudios = data?.places || [];
  const [placeList, setPlaceList] = useState(
    isManager
      ? ownersList[0]?.places?.map((item) => {
          return { label: item?.name, value: item?.id };
        })
      : ownersList
          ?.filter((owner) =>
            selectedOwners.some((ownerz) => ownerz.id === owner.value)
          )
          .flatMap((owner) => owner.places)
  );

  const filterOwnerList = ownersList?.filter((owner) =>
    selectedOwners.some((ownerz) => ownerz.id === owner.value)
  );
  const filteredPlaceList = placeList?.filter((place) =>
    selectedStudios.some((studio) => studio.id === place.value)
  );

  const handleChange = (event, value) => {
    const selectedOwners = value.getValue("linkToOwner") as Owners[];
    const selectedStudios = value.getValue("linkToStudios") as ListStudios[];

    let updatedSelectedStudios;
    if (selectedOwners) {
      const newPlaceList = selectedOwners?.flatMap(
        (owner) => owner.places || []
      );
      setPlaceList(newPlaceList);

      updatedSelectedStudios = newPlaceList?.filter((place) =>
        selectedStudios?.includes(place)
      );
    }

    if (updatedSelectedStudios && updatedSelectedStudios.length > 0) {
      formRef?.setValue("linkToStudios", updatedSelectedStudios);
    } else {
      formRef?.setValue("linkToStudios", null);
    }
  };

  const editManager = async (
    values: FormFullData<{
      fullName: string;
      email: string;
      cpf: string;
      linkToStudios: ListStudios[];
      linkToOwner: Owners[];
    }>
  ) => {
    let submitData = {
      id: data ? data.id : "",
      cpf: values.cpf,
      email: values.email,
      name: values.fullName,
      studiosIds: values.linkToStudios,
      usersIds: values.linkToOwner,
    };

    submitData.studiosIds = (submitData.studiosIds ?? [])
      .map((studio) => studio.value)
      .filter((id) => id !== "all");

    if (isManager) {
      submitData.usersIds = selectedOwners?.map((owner) => owner.value);
    } else {
      submitData.usersIds = (submitData.usersIds ?? [])
        .map((owner) => owner.value)
        .filter((id) => id !== "all");
    }

    call(null, api.putManagers(submitData), (response) => {
      if (response.ok) {
        filterManager && filterManager();
        customModal.close();
      }
    });
  };

  const createManager = async (
    values: FormFullData<{
      fullName: string;
      email: string;
      cpf: string;
      linkToStudios: ListStudios[];
      linkToOwner: Owners[];
    }>
  ) => {
    let submitData = {
      id: data ? data.id : "",
      cpf: values.cpf,
      email: values.email,
      name: values.fullName,
      studiosIds: values.linkToStudios,
      usersIds: values.linkToOwner,
    };

    submitData.studiosIds = (submitData.studiosIds ?? [])
      .map((studio) => studio.value)
      .filter((id) => id !== "all");

    if (isManager) {
      submitData.usersIds = selectedOwners?.map((owner) => owner.value);
    } else {
      submitData.usersIds = (submitData.usersIds ?? [])
        .map((owner) => owner.value)
        .filter((id) => id !== "all");
    }

    call(null, api.postManagers(submitData), (response) => {
      if (response.ok) {
        customModal.close();
      }
    });
  };

  const handleSubmit = async (
    values: FormFullData<{
      fullName: string;
      email: string;
      cpf: string;
      linkToStudios: ListStudios[];
      linkToOwner: Owners[];
    }>
  ) => {
    if (data) {
      editManager(values);
    } else {
      createManager(values);
    }
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          minWidth: "clamp(300px, 20vw, 650px)",
          paddingInline: "25px",
        }}
      >
        <FormFull formRef={setFormRef} onSubmit={handleSubmit}>
          <CustomText
            style={{ marginBottom: "20px" }}
            fontFamily={fonts.bold}
            fontSize={14 / 8}
            textColor="#B2B2B2"
          >
            DADOS BÁSICOS DO GESTOR
          </CustomText>
          <Grid alignItems="flex-end" container spacing={5}>
            <Grid item xs={12} md={12} lg={6}>
              <Input
                readOnly={viewOnly}
                disableError
                name="fullName"
                label="Nome Completo"
                defaultValue={data?.name || ""}
                validation={validations.isValidFullname}
                required={"*Campo Obrigatório"}
                white
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Input
                readOnly={viewOnly}
                disableError
                name="cpf"
                label="CPF"
                defaultValue={data?.cpf || ""}
                mask={masks.CPF}
                validation={validations.validateCPF}
                maskToSubmit={masks.removeNumberMask}
                required={"*Campo Obrigatório"}
                white
              />
            </Grid>
            <Grid item xs={12} md={6} lg={12}>
              <Input
                readOnly={viewOnly}
                disableError
                name="email"
                label="E-Mail"
                defaultValue={data?.email || ""}
                validation={validations.isEmailValid}
                required={"*Campo Obrigatório"}
                white
              />
            </Grid>
            {!isManager && (
              <Grid item xs={12} md={12} lg={12}>
                <InputTags
                  readOnly={viewOnly}
                  name="linkToOwner"
                  label="VÍNCULO A PROPRIETÁRIO"
                  options={ownersList ? ownersList : []}
                  required={"*Campo Obrigatório"}
                  defaultValue={data?.users ? filterOwnerList : null}
                  white
                  onChange={handleChange}
                />
              </Grid>
            )}
            <Grid item xs={12} md={12} lg={12}>
              <InputTags
                readOnly={viewOnly}
                name="linkToStudios"
                label={
                  isManager
                    ? "VINCULAR A STUDIOS "
                    : "VINCULAR A STUDIOS DO(S) PROPRIETÁRIO(S)"
                }
                options={placeList ? placeList : []}
                required={"*Campo Obrigatório"}
                defaultValue={data?.places ? filteredPlaceList : null}
                white
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "12px",
              paddingTop: "12px",
              width: "100%",
            }}
          >
            <Styles.CancelButton
              fullWidth={false}
              onClick={() => {
                customModal.close();
              }}
            >
              {viewOnly ? "Voltar" : "Cancelar"}
            </Styles.CancelButton>

            {!viewOnly && (
              <Styles.RegitryButton
                fullWidth={false}
                loading={loading}
                disabled={loading}
                onClick={async () => {
                  const submitInfo = await formRef?.testErrorsAndReturnData();

                  if (!submitInfo?.hasError) {
                    formRef?.submit();
                  }
                }}
              >
                Confirmar {data ? "Edição" : "Cadastro"}
              </Styles.RegitryButton>
            )}
          </div>
        </FormFull>
      </div>
    </>
  );
}

export default ModalRegistry;
