import styled from "styled-components";
import { Chip, withTheme } from "@material-ui/core";
import FontStyle from "../styles/fontStyles";

type TagProps = {
  outlined?: boolean;
  props?: any;
};

const StyledChip = styled(Chip)(({ theme, variant }) => ({
  "&&.MuiChip-root": {
    backgroundColor: "#24272A",
    ...FontStyle.semibold12,
    color: "#E0E0E0",
  },
  "&&.MuiChip-root.Mui-disabled": {
    backgroundColor: "#24272A",
    color: "#E0E0E0",
    opacity: 1, // Garante que a opacidade não seja alterada
  },
}));

function Tag({ outlined, ...props }: TagProps) {
  return (
    <StyledChip
      variant={outlined ? "outlined" : undefined}
      color="primary"
      {...props}
    />
  );
}

export default withTheme(Tag);
